import React from "react";
import { timeSince } from "../../../utils/function";
export default function MessageComponent({ message }) {
  return (
    <div className="cursor-pointer hover:bg-[#1B1B24] border-b border-[#222222]">
      <div className='flex items-center space-x-3 p-4'>
        <img
          src={message.profile.pfpUrl}
          alt=''
          className='w-12 h-12 rounded-full'
        />
        <div className='flex-col'>
          <div className='flex'>
            <p className=' text-gray-100 text-base font-semibold sm:hover:underline'>{message.profile.xDisplayName}</p>
            <p className='text-gray-400 font-thin ml-1'>@{message.profile.xUsername}</p>
            <p className='text-gray-400 font-thin ml-2'>{timeSince(message.Timestamp)}</p>
          </div>
          <p className='text-gray-400'>{message.lastMessage}</p>
        </div>
      </div>
    </div>
  );
}
