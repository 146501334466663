import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PrivyProvider } from "@privy-io/react-auth";
import PrivyState from "./AppStates/PrivyState";
import  { Toaster } from "react-hot-toast";
const root = ReactDOM.createRoot(document.getElementById("root"));
const blastSepolia = {
  id: 81457,
  name: 'Blast',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.blast.io'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Blastscan',
      url: 'https://blastscan.io',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
      blockCreated: 212929,
    },
  },
  testnet: false,
}

root.render(
  <PrivyProvider
    appId={"cls0olcdd01zqid0pj0qguyhw"}
    config={{
      // This configures wallet, email, Google, and Twitter login for your app.
      loginMethods: ["twitter"],
      appearance: {
        theme: "dark",
        accentColor: "#EAB308",
        
      },
    
      supportedChains: [blastSepolia],
      defaultChain: blastSepolia,
    }}
    onSuccess={(user) => console.log(`User ${user.id} logged in!`)}>
    <PrivyState>
      <Toaster/>
      <App />
    </PrivyState>
  </PrivyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
