import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useContext, useEffect } from "react";
import { MdOutlineClose } from "react-icons/md";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../AppStates/PrivyContext";
import FrenHiveContract from "../../API/FrenHiveContract";
import BigNumber from "bignumber.js";
function WithdrawModal({ showModal, setShowModal }) {
  const privyContextObject = useContext(PrivyContext);
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [canSend, setCanSend] = useState(false);
  const { sendTransaction, getAccessToken } = usePrivy();
  async function handleSendETH() {
    //check if amount is > 0 and address is valid
    if (amount <= 0) {
      return alert("Amount cannot be 0");
    }
    if (address.length !== 42) {
      return alert("Invalid address");
    }
    if (privyContextObject.hasLoggedIn) {
      const frenHiveContractObject = new FrenHiveContract(sendTransaction);

      const priceBigInt = new BigNumber(amount * 1e18);
      console.log(priceBigInt);
      const hexAmount = `0x${priceBigInt.toString(16)}`;

      const trimmedAddress = address.trim();
      const response = frenHiveContractObject.transferETH(
        privyContextObject.userData.Address,
        trimmedAddress,
        hexAmount
      );
      if (typeof response !== "undefined") {
        setShowModal(false);
      }
    }
  }

  useEffect(() => {
    if (address.length > 0 && amount > 0) {
      setCanSend(true);
    } else {
      setCanSend(false);
    }
  }, [address, amount]);
  return (
    <div className=''>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl border border-[#040405] text-left align-middle shadow-xl transition-all bg-[#1b1b24]'>
                  <div className='flex justify-end space-x-4 py-3 px-6 fixed -right-6 -top-4 z-50 '>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-l-lg border-none px-2 py-2 text-sm font-medium  focus:outline-none bg-slate-800 hover:bg-slate-900 text-white'
                      onClick={() => setShowModal(false)}>
                      <MdOutlineClose size={24} />
                    </button>
                  </div>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-xl text-center my-4 font-semibold text-gray-100'>
                    Withdraw ETH
                  </Dialog.Title>
                  <div className='mt-4 '>
                    <div className='px-6'>
                      <div className='flex flex-col justify-between w-full  '>
                        <div className='flex flex-col'>
                          <p className='text-gray-300 text-base font-semibold ml-1'>
                            Address
                          </p>
                          <input
                            type='text'
                            placeholder='Address'
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className='bg-slate-800 rounded-xl px-3 py-3 w-full focus:outline-none text-gray-300 text-base mt-1'
                          />
                        </div>
                        <div className='flex flex-col mt-4'>
                          <p className='text-gray-300 text-base font-semibold ml-1'>
                            ETH Amount
                          </p>
                          <input
                            type='number'
                            placeholder='Amount'
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            className='bg-slate-800 rounded-xl px-3 py-3 w-full focus:outline-none text-gray-300 text-base mt-1'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex justify-center my-4'>
                      <button
                        onClick={() => {
                          handleSendETH();
                        }}
                        className={`${
                          canSend
                            ? "bg-yellow-500 hover:bg-yellow-600"
                            : "bg-gray-400"
                        } text-black rounded-xl px-5 py-2 `}>
                        Withdraw
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default WithdrawModal;
