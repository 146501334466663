import React, { Fragment, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import defaultPfp from "../../assets/default-profile-pic.png";
import { BiChevronDown, BiHomeAlt } from "react-icons/bi";
import { Menu, Transition } from "@headlessui/react";
import PrivyContext from "../../AppStates/PrivyContext";
import TradeModal from "../Modals/TradeModal";
import UserBox from "./UserBox";
const RightSideBar = ({ isMobile }) => {
  const { ready, authenticated, user, logout } = usePrivy();
  const hasWallet = typeof user?.wallet !== "undefined";
  const hasTwitter = typeof user?.twitter !== "undefined";
  const privyContextObject = useContext(PrivyContext);

  const [showModal, setShowModal] = React.useState(false);
  if (isMobile) {
    return null; // No right component on mobile
  }

  const handleLogout = () => {
    logout();
    privyContextObject.updateHasLoggedIn(false);
    privyContextObject.updateUserData({});
  };

  return (
    <div
      className='fixed xl:pr-40 sm:pr-20  bg-[#08080B]'
      style={{
        overflowY: "auto",
        height: "100vh",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}>
      <div className='border-l border-[#222222] pt-5 pl-5 flex justify-center w-full '>
        {hasTwitter && hasWallet && (
          <div className=' w-full flex justify-end'>
            <div className='flex flex-row items-center justify-end bg-[#1B1B24] text-gray-200 px-3 py-3 rounded-md'>
              <Menu as='div' className='relative w-full flex text-left'>
                <Menu.Button className='flex w-full menu space-x-1 items-center justify-center focus:outline-none'>
                  <div className='flex space-x-3 items-center justify-center'>
                    <img
                      src={
                        Object.keys(privyContextObject.userData).length === 0
                          ? defaultPfp
                          : privyContextObject.userData.pfpUrl
                      }
                      alt=''
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultPfp;
                      }}
                      className='rounded-full w-6 h-6 md:w-8 md:h-8 '
                    />
                    <span className='hidden md:flex text-sm'>
                      GM, {user.twitter.username}
                    </span>
                  </div>
                  <BiChevronDown size={20} />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <Menu.Items className='absolute -right-3  z-10 mt-12 w-56 divide-y divide-gray-400 dark:divide-[#2D2D33] rounded-md  shadow-lg border-gray-800 border-2 ring-1 ring-black ring-opacity-5 py-1 focus:outline-none bg-[#1B1B24] text-gray-200'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "text-yellow-500" : ""
                          } flex justify-between menu w-full px-4 py-2 `}
                          to={`/u/${user.twitter.username}`}>
                          My Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "text-yellow-500" : ""
                          } flex justify-between menu w-full px-4 py-2 `}
                          to={`/settings`}>
                          Account Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleLogout()}
                          className={`${
                            active ? "text-yellow-500" : ""
                          } cursor-pointer flex menu justify-between w-full px-4 py-2`}>
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        )}
        {!hasTwitter && !hasWallet && (
          <div className='flex justify-end w-full space-x-2'>
            <Link
              className='border-yellow-500 hover:bg-yellow-500 text-gray-300 hover:text-black border-2 rounded-lg px-8 py-2'
              to='/login'>
              Login
            </Link>
            <Link
              className='bg-yellow-500 hover:bg-yellow-600 text-black rounded-lg px-8 py-2'
              to='/login'>
              Sign Up
            </Link>
          </div>
        )}
      </div>
      <div className=''>
        <div className='w-full mt-16 py-4 px-5 mx-5 bg-[#1b1b24] rounded-2xl'>
          <h1 className='text-white font-bold text-2xl'>Trending Creators</h1>
          <div className='flex flex-col items-start mt-4 space-y-3'>
            {privyContextObject.trendingUsers.map((user, index) => {
              const currentUserObject = {
                Address: user.address,
                DisplayName: user.displayName,
                xUsername: user.username,
                pfpUrl: user.pfp,
              };
              return (
                <UserBox
                  user={user}
                  currentUserObject={currentUserObject}
                  ethPrice={privyContextObject.ethPrice}
                />
              );
            })}
          </div>
        </div>

        <div className='w-full mt-3  py-4 px-5 mx-5 bg-[#1b1b24] rounded-2xl'>
          <h2 className='text-gray-100'>
            Refer new creators and earn 100% of their fees 🎉
          </h2>
          <div className='mt-4 mb-2'>
            <Link
              className='bg-yellow-500 hover:bg-yellow-600 text-black rounded-xl px-5 py-2'
              to={`/wallet?tab=referrals`}>
              Refer Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightSideBar;
