import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { FaDiscord } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

import { usePrivy } from "@privy-io/react-auth";
export default function Landing() {
  const navigate = useNavigate();
  const { authenticated } = usePrivy();
  useEffect(() => {
    if (authenticated) {
      navigate("/home");
    }
  }, [authenticated]);
  return (
    <div className='min-h-screen bg-[#08080B]'>
      <section className=''>
        <div className='mx-auto flex justify-center pt-36'>
          <div className=''>
            <h1 className='max-w-2xl mb-4 text-6xl font-extrabold leading-none tracking-tight md:text-6xl xl:text-7xl text-white text-center'>
              The SocialFi to have a{" "}
              <span className='text-[#e5e74c]'>Blast!</span>
            </h1>
            <p className='max-w-2xl mb-6 font-light text-gray-300 lg:mb-8 md:text-lg lg:text-xlte text-center'>
              Trade Social Tokens, earn rewards, and build a hive of frens.
            </p>
          </div>
        </div>

        <div className='mx-auto flex justify-center my-10'>
          <div className=''>
            <Link
              className='bg-[#e5e74c] hover:bg-[#f7fa68] text-black font-bold py-2 px-4 rounded-md shadow-sm'
              to='/home'>
              Enter The Hive
            </Link>
          </div>
        </div>
        <div>
          <div className='flex mx-auto justify-center flex-col items-center space-y-8'>
            <p className='text-white text-center'>
              Follow us on our socials 🌱
            </p>
            <div className='flex space-x-2'>
              <a href='https://discord.gg/qEWh29cMv2' target='_blank'>
                <FaDiscord
                  className='text-indigo-500 text-4xl ml-2'
                  size={42}
                />
              </a>
              <a href='https://twitter.com/frenhive' target='_blank'>
                <FaTwitter className='text-blue-400 text-4xl ml-2' size={42} />
              </a>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
