import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import FrenHiveContract from "../../API/FrenHiveContract";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../AppStates/PrivyContext";
import { useContext } from "react";
import API from "../../API/API";
import toast from "react-hot-toast";
import BigNumber from "bignumber.js";
function TradeModal({ showModal, setShowModal, isBuy = true, userObject }) {
  const { user, sendTransaction, getAccessToken } = usePrivy();
  const privyContextObject = useContext(PrivyContext);
  const [isBuying, setIsBuying] = useState(isBuy);
  const [numerOfKeys, setNumberOfKeys] = useState(1);

  const [sharesToSell, setSharesToSell] = useState(1);
  const [payingAmountETH, setPayingAmountETH] = useState(0);
  const [gettingAmountETH, setGettingAmountETH] = useState(0);
  const [buyPrice, setBuyPrice] = useState(0);
  const [loadingData, setLoadingData] = useState(true);

  const [isFirstBuy, setIsFirstBuy] = useState(false);

  const [sharesHeld, setSharesHeld] = useState(0);

  useEffect(() => {
    setIsBuying(isBuy);
  }, [isBuy]);

  useEffect(() => {
    if (showModal) {
      loadTradeData(isBuying);
    }
  }, [isBuying, showModal]);

  async function handleTrade(trade = "buy") {
    if (!user) {
      toast.error("Please Login to Swap Keys.", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
    }
    if (loadingData) {
      return;
    }
    const sharesToTrade = isBuying ? numerOfKeys : sharesToSell;
    console.log(sharesToTrade);
    if (sharesToTrade === "0" || sharesToTrade === "" || sharesToTrade === 0) {
      toast.error("Please enter a valid number of keys to trade.", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      return;
    }
    const contractObject = new FrenHiveContract(sendTransaction);
    console.log(privyContextObject.userData);
    console.log(userObject.Address);
    const selfAddress = privyContextObject.userData.Address;

    if (trade === "buy") {
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);
      const sharesToBuy = sharesToTrade * 1e6;
      const result = await ApiObject.getKeyPrice(
        userObject.Address,
        sharesToBuy
      );
      console.log(result.price);

      // checking if it is first buy
      let isFirstBuy = false;
      if (sharesToTrade == 1) {
        if (result.price === "0") {
          isFirstBuy = true;
        }
      } else {
        const firstSharePrice = await ApiObject.getKeyPrice(
          userObject.Address,
          1e6
        );
        if (firstSharePrice.price === "0") {
          isFirstBuy = true;
        }
      }

      const priceBigInt = new BigNumber(result.price);
      const hexPrice = `0x${priceBigInt.toString(16)}`;
      console.log(hexPrice);

      if (result.price) {
        if (isFirstBuy) {
          console.log("IT IS FIRST BUY...");
          if (selfAddress === userObject.Address) {
            const authToken = await getAccessToken();
            const ApiObject2 = new API(authToken);
            let refreeAddress = "";
            try {
              const response = await ApiObject2.whoReferredThisUser();
              refreeAddress = response.address;
            } catch (error) {
              console.log(error);
            }

            if (refreeAddress !== "" && typeof refreeAddress !== "undefined") {
              console.log("Refree Address: ", refreeAddress);
              const response3 = await contractObject.handleFirstBuyShares(
                userObject.Address,
                sharesToBuy,
                hexPrice,
                refreeAddress
              );
              console.log(response3);
            } else {
              console.log("No refree found...");
              const response = await contractObject.handleBuyShares(
                selfAddress,
                sharesToBuy,
                userObject.Address,
                hexPrice // eth value we send along in hex
              );
              if (typeof response !== "undefined") {
                setShowModal(false);
              }
            }
          } else {
            setIsFirstBuy(true);
            return;
          }
        } else {
          console.log("IT IS NOT FIRST BUY...");
          const response = await contractObject.handleBuyShares(
            selfAddress,
            sharesToBuy,
            userObject.Address,
            hexPrice // eth value we send along in hex
          );
          console.log(response);
          if (typeof response !== "undefined") {
            setShowModal(false);
          }
        }
      } else {
        // error
        console.log("No price returned");
      }
    } else {
      // selling the share now

      const response = await contractObject.handleSellShares(
        selfAddress,
        sharesToTrade * 1e6,
        userObject.Address
      );
      console.log(response);
      if (typeof response !== "undefined") {
        setShowModal(false);
      }
    }
  }

  async function loadTradeData(isBuy) {
    if (!userObject.Address) {
      return;
    }

    try {
      if (isBuy) {
        console.log("loading buying data");
        const authToken = await getAccessToken();
        const ApiObject = new API(authToken);
        const buyPrice = await ApiObject.getKeyPrice(
          userObject.Address,
          numerOfKeys * 1e6
        );
        setBuyPrice(buyPrice.price);
        setPayingAmountETH(
          Math.round((buyPrice.price / 1e18) * 100000) / 100000
        );
        setLoadingData(false);
      } else {
        console.log("loading selling data");
        const authToken = await getAccessToken();
        const selfAddress = privyContextObject.userData.Address;
        const ApiObject = new API(authToken);
        const sellingPrice = await ApiObject.getSellingPrice(
          userObject.Address,
          sharesToSell * 1e6
        );
        setGettingAmountETH(
          Math.round((sellingPrice.price / 1e18) * 100000) / 100000
        );
        const holdingAmount = await ApiObject.getHoldingOfAddress(
          selfAddress,
          userObject.Address
        );
        setLoadingData(false);
        setSharesHeld(holdingAmount.shares);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function handleChangeInKeysTraded(value, isBuy) {
    //check if value has only 4 digits after decimal
    if (value.includes(".")) {
      let decimalPart = value.split(".")[1];
      if (decimalPart.length > 4) {
        return;
      }
    }
    if (isBuy) {
      setNumberOfKeys(value);
    } else {
      setSharesToSell(value);
    }
    if (value === "" || value === 0) {
      setLoadingData(true);
    }

    try {
      if (isBuy && value > 0) {
        console.log("loading buying data");
        setLoadingData(true);
        const authToken = await getAccessToken();
        const ApiObject = new API(authToken);
        const buyPrice = await ApiObject.getKeyPrice(
          userObject.Address,
          value * 1e6
        );
        setBuyPrice(buyPrice.price);
        setPayingAmountETH(
          Math.round((buyPrice.price / 1e18) * 100000) / 100000
        );
        setLoadingData(false);
      } else {
        console.log("loading selling data");
        setLoadingData(true);
        const authToken = await getAccessToken();
        const ApiObject = new API(authToken);
        const sellPrice = await ApiObject.getSellingPrice(
          userObject.Address,
          value * 1e6
        );
        console.log(sellPrice);
        setGettingAmountETH(
          Math.round((sellPrice.price / 1e18) * 100000) / 100000
        );
        setLoadingData(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className=''>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl border border-[#040405] text-left align-middle shadow-xl transition-all bg-[#1b1b24]'>
                  <div className='flex justify-end space-x-4 py-3 px-6 fixed -right-6 -top-4 z-50 '>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-l-lg border-none px-2 py-2 text-sm font-medium  focus:outline-none bg-slate-800 hover:bg-slate-900 text-white'
                      onClick={() => setShowModal(false)}>
                      <MdOutlineClose size={24} />
                    </button>
                  </div>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-xl text-center my-4 font-semibold text-gray-100'>
                    Swap Keys
                  </Dialog.Title>
                  <div className='mt-4 px-6'>
                    <div>
                      <div className='flex justify-between items-center w-full '>
                        <button
                          className={`${
                            isBuying
                              ? "bg-[#132D29] border-green-500  border-2  "
                              : "bg-gray-800 "
                          } text-white w-full py-2 px-2 rounded-r-md rounded-l-lg`}
                          onClick={() => setIsBuying(true)}>
                          Buy
                        </button>
                        <button
                          className={`${
                            !isBuying
                              ? "bg-[#241E24] border-red-500 border-2 "
                              : "bg-gray-800 "
                          }  text-white w-full px-2 py-2 rounded-r-lg rounded-l-md`}
                          onClick={() => setIsBuying(false)}>
                          Sell
                        </button>
                      </div>

                      {isBuying && (
                        <div>
                          <p className='text-gray-400 text-sm mt-4'>
                            You are paying ~$
                            {(
                              Math.round(
                                (buyPrice / 1e18) *
                                  privyContextObject.ethPrice *
                                  100
                              ) / 100
                            ).toLocaleString()}
                          </p>
                          <div className='mt-2  bg-slate-800 rounded-md flex'>
                            <div className='flex justify-start items-center px-4 py-5 '>
                              <FaEthereum size={20} className='text-gray-400' />
                              <p className='text-gray-400 text-sm ml-2'>
                                Ethereum
                              </p>
                            </div>
                            <input
                              className='bg-slate-800 outline-none border-none text-gray-100 w-full px-4 py-3 rounded-md mt-2 appearance-none text-end'
                              type='number'
                              value={payingAmountETH}

                              // onChange={(e) =>
                              //   setPayingAmountETH(e.target.value)
                              // } // let's now allow user to change eth value for now
                            />
                          </div>
                          <p className='text-gray-400 text-sm mt-4'>
                            to buy {numerOfKeys} {userObject.xUsername} key
                          </p>

                          <div className='mt-2 bg-slate-800 rounded-md flex '>
                            <div className='flex justify-start items-center px-4 py-3 w-full'>
                              <img
                                src={userObject.pfpUrl}
                                className='h-9 w-9 rounded-full'
                              />
                              <p className='text-gray-400 text-sm ml-2'>
                                {userObject.xUsername} key
                              </p>
                            </div>
                            <input
                              className='bg-slate-800 outline-none border-none text-gray-100 w-full px-4 py-3 rounded-md mt-2 appearance-none text-end'
                              type='number'
                              value={numerOfKeys}
                              onChange={(e) =>
                                handleChangeInKeysTraded(
                                  e.target.value,
                                  (isBuy = true)
                                )
                              }
                            />
                          </div>

                          <div className='my-4 w-full mb-5'>
                            <button
                              className={`${
                                loadingData
                                  ? "bg-gray-700 text-gray-400 "
                                  : isFirstBuy
                                  ? "bg-gray-700 text-gray-400"
                                  : "bg-green-500 hover:bg-green-600"
                              }  text-white px-5 py-3 rounded-md w-full`}
                              onClick={() => {
                                handleTrade("buy");
                              }}>
                              {loadingData
                                ? "Loading..."
                                : isFirstBuy
                                ? "NOT TRADABLE"
                                : "BUY"}
                            </button>
                          </div>
                        </div>
                      )}

                      {!isBuying && (
                        <div>
                          <p className='text-gray-400 text-sm mt-4'>
                            You are selling {sharesToSell} key
                            {sharesToSell > 1 && "s"} out of {sharesHeld} key
                            {sharesHeld > 1 && "s"} you own.
                          </p>
                          <div className='mt-2 bg-slate-800 rounded-md flex '>
                            <div className='flex justify-start items-center px-4 py-3 w-full'>
                              <img
                                src={userObject.pfpUrl}
                                className='h-9 w-9 rounded-full'
                              />
                              <p className='text-gray-400 text-sm ml-2'>
                                {userObject.xUsername} key
                              </p>
                            </div>
                            <input
                              className='bg-slate-800 outline-none border-none text-gray-100 w-full px-4 py-3 rounded-md mt-2 appearance-none text-end'
                              type='number'
                              value={sharesToSell}
                              onChange={(e) =>
                                handleChangeInKeysTraded(
                                  e.target.value,
                                  (isBuy = false)
                                )
                              }
                            />
                          </div>
                          <p className='text-gray-400 text-sm mt-4'>
                            to receive $
                            {(
                              Math.round(
                                gettingAmountETH *
                                  privyContextObject.ethPrice *
                                  100
                              ) / 100
                            ).toLocaleString()}
                          </p>
                          <div className='mt-2  bg-slate-800 rounded-md flex'>
                            <div className='flex justify-start items-center px-4 py-5 '>
                              <FaEthereum size={20} className='text-gray-400' />
                              <p className='text-gray-400 text-sm ml-2'>
                                Ethereum
                              </p>
                            </div>
                            <input
                              className='bg-slate-800 outline-none border-none text-gray-100 w-full px-4 py-3 rounded-md mt-2 appearance-none text-end'
                              type='number'
                              value={gettingAmountETH}
                              // onChange={(e) =>
                              //   setPayingAmountETH(e.target.value)
                              // } // lt's not let the user change this for now
                            />
                          </div>

                          <div className='my-4 w-full mb-5'>
                            <button
                              className={`${
                                loadingData
                                  ? "bg-gray-700 text-gray-400 "
                                  : "bg-red-500 hover:bg-red-600"
                              }  text-white px-5 py-3 rounded-md w-full`}
                              onClick={() => {
                                handleTrade("sell");
                              }}>
                              {loadingData ? "Loading..." : "SELL"}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default TradeModal;
