import React, { Fragment, useEffect, useState } from "react";
import CreatePost from "./subComponents/CreatePost";
import PostBox from "./subComponents/PostBox";
import TradesBox from "./subComponents/TradesBox";
import NewFrens from "./subComponents/NewFrens";
import Header from "../Header";
import CreatePostMobile from "./subComponents/CreatePostMobile";
import PrivyContext from "../../AppStates/PrivyContext";
import { useContext } from "react";
import API from "../../API/API";
import { FaHotjar } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";
import { useDetectClickOutside } from "react-detect-click-outside";
import InfiniteScroll from "react-infinite-scroll-component";
const FeedBox = ({ isMobile = false }) => {
  const [isDropdowExpanded, setIsDropdownExpanded] = useState(false);
  const [navTab, setNavTab] = useState("Hive");
  const privyContextObject = useContext(PrivyContext);

  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMoreNew, setHasMoreNew] = useState(true);
  const [hasMoreHot, setHasMoreHot] = useState(true);
  const [loadingHiveFeed, setLoadingHiveFeed] = useState(
    privyContextObject.hiveFeed.length <= 0
  );

  const [loadingNewFeed, setLoadingNewFeed] = useState(
    privyContextObject.newFeed.length <= 0
  );

  const [loadingTrades, setLoadingTrades] = useState(
    privyContextObject.trades <= 0
  );
  const closeDropdown = () => {
    console.log("is triggered");
    setIsDropdownExpanded(false);
  };

  async function handlePostFeedChange(newTab) {
    privyContextObject.updatePostTab(newTab);
    setIsDropdownExpanded(false);
    try {
      if (newTab === "hot") {
        initializeHotFeed();
      } else {
        initializeNewPostFeed();
      }
    } catch (error) {
      console.log(error);
    }
  }
  const ref = useDetectClickOutside({ onTriggered: closeDropdown });

  async function initializeFeed(tab) {
    try {
      const ApiObject = new API();
      if (tab === "Hive") {
        if (privyContextObject.postTab === "hot") {
          initializeHotFeed();
        } else {
          initializeNewPostFeed();
        }
      } else if (tab === "Trades") {
        const trades = await ApiObject.getTrades();
        console.log(trades);

        privyContextObject.updateTrades(trades);

        setLoadingTrades(false);
      } else if (tab === "NewFrens") {
        let newUsers = await ApiObject.getNewUsers(50);
        //loop through newUsers and remove the object that has username === null
        newUsers = newUsers.filter((user) => user.username !== null);

        privyContextObject.updateNewUsers(newUsers);
        console.log(newUsers);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function initializeHotFeed() {
    const ApiObject = new API();
    const feed = await ApiObject.getHiveFeed();
    privyContextObject.updateHiveFeed(feed);
    setLoadingHiveFeed(false);
  }
  async function initializeNewPostFeed() {
    const ApiObject = new API();
    const feed = await ApiObject.getNewPosts(40);
    privyContextObject.updateNewFeed(feed);
    setLoadingNewFeed(false);
  }

  async function getMorePosts() {
    try {
      if (privyContextObject.postTab === "hot") {
        setLoadingMore(true);
        const ApiObject = new API();
        const lastPost =
          privyContextObject.hiveFeed[privyContextObject.hiveFeed.length - 1];
        const feed = await ApiObject.getHiveFeed(
          lastPost.Timestamp,
          50,
          lastPost.hotness_score
        );

        //loop through feed and remove the object that has same PostID either in hiveFeed or in feed variable
        const filteredFeed = feed.filter(
          (post) =>
            !privyContextObject.hiveFeed.some(
              (postObject) => postObject.PostID === post.PostID
            )
        );
        // if filteredFeed is empty, then set hasMoreNew to false and return
        console.log(filteredFeed);
        if (filteredFeed.length <= 0) {
          setHasMoreHot(false);
          setLoadingMore(false);
          return;
        }
        privyContextObject.updateHiveFeed([
          ...privyContextObject.hiveFeed,
          ...filteredFeed,
        ]);
        setLoadingMore(false);
      } else if (privyContextObject.postTab === "new") {
        console.log(hasMoreNew);
        if (hasMoreNew) {
          setLoadingMore(true);
          const ApiObject = new API();
          const newFeed = await ApiObject.getNewPosts(
            50,
            privyContextObject.newFeed[privyContextObject.newFeed.length - 1]
              .Timestamp
          );

          //if timestamp of last post in feed is more than first post in new feed, then set hasMoreNew to false
          if (newFeed.length <= 0) {
            setHasMoreNew(false);
            return;
          }
          privyContextObject.updateNewFeed([
            ...privyContextObject.newFeed,
            ...newFeed,
          ]);
          setLoadingMore(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    initializeFeed(navTab);
  }, [navTab]);

  return (
    <div
      className={`sm:border-x border-[#222222] min-h-screen w-full ${
        navTab === "Hive" && "sm:bg-[#1b1b24]  bg-[#08080B]"
      } `}>
      <div className='flex shadow-md '>
        {" "}
        {isMobile && (
          <div className='fixed w-full top-0'>
            <Header />
            <div className='w-full flex   border-[#222222] border-b  bg-[#08080B]  l-0 r-0 -z-1'>
              <button
                className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                  navTab === "Hive" &&
                  " border-b-2  border-yellow-500 text-white"
                }`}
                onClick={() => setNavTab("Hive")}>
                Hive
              </button>
              <button
                className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                  navTab === "Trades" &&
                  " border-b-2  border-yellow-500 text-white"
                }`}
                onClick={() => setNavTab("Trades")}>
                Trades
              </button>
              <button
                className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                  navTab === "NewFrens" &&
                  " border-b-2  border-yellow-500 text-white"
                }`}
                onClick={() => setNavTab("NewFrens")}>
                New Frens
              </button>
            </div>
          </div>
        )}
        {!isMobile && (
          <div className='w-full flex   border-[#222222] border-y  bg-[#08080B] fixed l-0 r-0 -z-1'>
            <button
              className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                navTab === "Hive" && " border-b-2  border-yellow-500 text-white"
              }`}
              onClick={() => setNavTab("Hive")}>
              Hive
            </button>
            <button
              className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                navTab === "Trades" &&
                " border-b-2  border-yellow-500 text-white"
              }`}
              onClick={() => setNavTab("Trades")}>
              Trades
            </button>
            <button
              className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                navTab === "NewFrens" &&
                " border-b-2  border-yellow-500 text-white"
              }`}
              onClick={() => setNavTab("NewFrens")}>
              New Frens
            </button>
          </div>
        )}
      </div>

      {navTab === "Hive" && (
        <>
          {!isMobile && (
            <div className='mt-16'>
              <CreatePost />
            </div>
          )}
          {isMobile && (
            <div className=' fixed bottom-20 right-4'>
              <CreatePostMobile />
            </div>
          )}

          <div className='mt-28 sm:mt-0  sm:mb-0'>
            <div className='flex justify-end w-full bg-[#08080B] border-[#222222] border-b '>
              <div ref={ref} className=''>
                {privyContextObject.postTab === "hot" && (
                  <button
                    className='flex border-l w-44 sm:hover:bg-[#1B1B24] border-[#222222] items-center  menu transition  text-gray-200 py-3 px-5 '
                    onClick={() => {
                      setIsDropdownExpanded(!isDropdowExpanded);
                    }}>
                    <div className='flex items-center'>
                      <FaHotjar className='w-5 h-5 text-gray-300' />
                      <span className='flex flex-col ml-2 '>Hot Feed</span>
                    </div>

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      className='w-5 h-5 dark:text-white'>
                      <path
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'></path>
                    </svg>
                  </button>
                )}

                {privyContextObject.postTab === "new" && (
                  <button
                    className='flex border-l w-44 sm:hover:bg-[#1B1B24] border-[#222222] items-center  menu transition  text-gray-200 py-3 px-5 '
                    onClick={() => {
                      setIsDropdownExpanded(!isDropdowExpanded);
                    }}>
                    <div className='flex items-center'>
                      <MdWavingHand className='w-5 h-5 text-gray-300' />
                      <span className='flex flex-col ml-2 '>New Feed</span>
                    </div>

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 20 20'
                      fill='currentColor'
                      className='w-5 h-5 dark:text-white'>
                      <path
                        d='M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z'
                        clipRule='evenodd'></path>
                    </svg>
                  </button>
                )}

                <div
                  className={` ${
                    isDropdowExpanded ? "flex" : "hidden"
                  } absolute bg-[#08080B]  w-44 flex-col  border-l border-b sm:hover:bg-[#1B1B24] border-[#222222] `}
                  style={{
                    zIndex: 100,
                  }}>
                  <div className='flex flex-col  '>
                    <button
                      className='flex items-center  menu transition  text-gray-200 py-3 px-5 '
                      onClick={() => {
                        handlePostFeedChange(
                          privyContextObject.postTab === "hot" ? "new" : "hot"
                        );
                      }}>
                      {privyContextObject.postTab === "hot" && (
                        <div className='flex items-center'>
                          {" "}
                          <MdWavingHand className='w-5 h-5 text-gray-300' />
                          <span className='flex flex-col ml-2 '>
                            New Feed
                          </span>{" "}
                        </div>
                      )}

                      {privyContextObject.postTab === "new" && (
                        <div className='flex items-center'>
                          {" "}
                          <FaHotjar className='w-5 h-5 text-gray-300' />
                          <span className='flex flex-col ml-2 '>
                            Hot Feed
                          </span>{" "}
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {loadingHiveFeed && privyContextObject.postTab === "hot" && (
              <div className='flex justify-center items-center  bg flex-col space-y-2 bg-[#08080B] min-h-screen'>
                <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
              </div>
            )}
            {loadingNewFeed &&
              !loadingHiveFeed &&
              privyContextObject.postTab === "new" && (
                <div className='flex justify-center items-center bg flex-col space-y-2 bg-[#08080B] min-h-screen'>
                  <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
                </div>
              )}
            {!loadingHiveFeed && privyContextObject.postTab === "hot" && (
              <InfiniteScroll
                dataLength={privyContextObject.hiveFeed.length}
                next={getMorePosts}
                hasMore={hasMoreHot}
                loader={
                  <div className='flex justify-center items-center w-full overflow-hidden py-3 flex-col space-y-2 bg-[#08080B]'>
                    <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-yellow-500'></div>
                  </div>
                }
                endMessage={
                  <p className='text-gray-200 text-lg  pt-10 text-center bg-[#08080B]'>
                    You have seen it all
                  </p>
                }>
                {privyContextObject.hiveFeed.map((postObject) => {
                  return (
                    <PostBox
                      postObject={postObject}
                      ethPrice={privyContextObject.ethPrice}
                      // userInfo={userInfoTemp}
                    />
                  );
                })}
              </InfiniteScroll>
            )}

            {!loadingNewFeed && privyContextObject.postTab === "new" && (
              <InfiniteScroll
                dataLength={privyContextObject.newFeed.length}
                next={getMorePosts}
                hasMore={hasMoreNew}
                loader={
                  <div className='flex justify-center items-center w-full overflow-hidden py-3 flex-col space-y-2 bg-[#08080B]'>
                    <div className='animate-spin rounded-full h-16 w-16 border-b-2 border-yellow-500'></div>
                  </div>
                }
                endMessage={
                  <p className='text-gray-200 text-lg  pt-10 text-center bg-[#08080B]'>
                    You have seen it all
                  </p>
                }>
                {privyContextObject.newFeed.map((postObject) => {
                  return (
                    <PostBox
                      postObject={postObject}
                      ethPrice={privyContextObject.ethPrice}
                      // userInfo={userInfoTemp}
                    />
                  );
                })}
              </InfiniteScroll>
            )}
          </div>
        </>
      )}

      {navTab === "Trades" && (
        <div className='bg-[#08080B]'>
          <div className='mt-20'>
            <h2 className='text-gray-100 text-2xl font-bold ml-5'>
              Recent Trades
            </h2>
          </div>
          {loadingTrades && (
            <div className='flex justify-center items-center mt-4 bg flex-col space-y-2 bg-[#08080B] min-h-screen'>
              <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
            </div>
          )}
          <div className='ml-5'>
            {!loadingTrades &&
              privyContextObject.trades.trades.map((tradesObject) => {
                const subject = tradesObject.subject;
                const trader = tradesObject.trader;

                // check if subject and trader are in privyContextObject.users
                if (
                  privyContextObject.trades.users.hasOwnProperty(subject) &&
                  privyContextObject.trades.users.hasOwnProperty(trader)
                ) {
                  return (
                    <TradesBox
                      trade={tradesObject}
                      userList={privyContextObject.trades.users}
                      ethPrice={privyContextObject.ethPrice}
                    />
                  );
                }
              })}
          </div>
        </div>
      )}

      {navTab === "NewFrens" && (
        <div className='bg-[#08080B]'>
          <div className='mt-20 mb-3'>
            <h2 className='text-gray-100 text-2xl font-bold ml-5'>
              New Frens 🎉
            </h2>
          </div>
          <div className='ml-5'>
            {privyContextObject.newUsers.map((newFrensObject) => {
              return <NewFrens user={newFrensObject} />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedBox;
