import React, { useState, useEffect, useContext } from "react";
import LeftSideBar from "../../components/LeftSidebar/LeftSidebar";

import RightSideBar from "../../components/RightSidebar/RightSidebar";
import TradeModal from "../../components/Modals/TradeModal";
import PostBox from "../../components/FeedBox/subComponents/PostBox";
import HolderList from "./HolderList";
import toast from "react-hot-toast";
import API from "../../API/API";
import { usePrivy } from "@privy-io/react-auth";
import { useParams } from "react-router-dom";
import PrivyContext from "../../AppStates/PrivyContext";
import HoldingList from "./HoldingList";
import defaultPfp from "../../assets/default-profile-pic.png";
import Tippy from "@tippyjs/react";
import Header from "../../components/Header";
import InfiniteScroll from "react-infinite-scroll-component";
const User = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [navTab, setNavTab] = useState("Posts");
  const [currentUser, setCurrentUser] = useState({});
  const [userPosts, setUserPosts] = useState([]);
  const [loadingUserInfo, setLodingUserInfo] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const { username } = useParams();
  const { getAccessToken } = usePrivy();

  const [holdersList, setHoldersList] = useState([]);
  const [holdingsList, setHoldingsList] = useState([]);
  const [loadingHolders, setLoadingHolders] = useState(true);
  const [loadingHoldings, setLoadingHoldings] = useState(true);

  const [doesUserExits, setDoesUserExits] = useState(false);

  const privyContextObject = useContext(PrivyContext);
  const [showModal, setShowModal] = useState(false);

  const [hasMore, setHasMore] = useState(true);
  // const [oldUsername, setOldUsername] = useState("")
  const handleBuyClick = () => {
    console.log("test..");
    setShowModal(true);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleWalletClick = () => {
    // console.log("clicked");
    //copy the address to clipboard
    navigator.clipboard.writeText(currentUser.Address);

    toast.success("Address copied to clipboard", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  };

  async function initUser() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const result = await ApiObject.getUserInfo(username);
    //check if result.status is 400
    if (result.status === 400) {
      setDoesUserExits(false);
      setLodingUserInfo(false);
      return;
    }
    setDoesUserExits(true);
    console.log(result);
    setCurrentUser(result);
    setLodingUserInfo(false);
  }

  async function getUserPosts() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const result = await ApiObject.getPostsByUsername(username);
    console.log(result);
    setUserPosts(result.posts);
    setLoadingPosts(false);
  }

  async function getMoreUserPosts() {
    // have to write this...
    try {
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);
      const result = await ApiObject.getPostsByUsername(
        username,
        50,
        userPosts[userPosts.length - 1].Timestamp
      );
      console.log(result);
      if (result.posts.length === 0) {
        setHasMore(false);
        return;
      }
      setUserPosts([...userPosts, ...result.posts]);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    console.log(username);
    if (privyContextObject.hasLoggedIn && username) {
      initUser();
      getUserPosts();
    }
  }, [username, privyContextObject.hasLoggedIn]);

  async function handleNavChange(nav) {
    try {
      setNavTab(nav);
      if (nav === "Holders") {
        const authToken = await getAccessToken();
        const ApiObject = new API(authToken);
        const result = await ApiObject.getHoldersOfUser(username);
        console.log(result);
        setHoldersList(result.holders);
        setLoadingHolders(false);
      } else if (nav === "Holdings") {
        const authToken = await getAccessToken();
        const ApiObject = new API(authToken);
        const result = await ApiObject.getHoldingsOfUser(username);
        console.log(result);
        setHoldingsList(result.holdings);
        setLoadingHoldings(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='bg-[#08080B] min-h-screen '>
      <div className='flex  sm:w-full lg:w-full xl:w-4/5  mx-auto sm:px-10 min-h-screen  '>
        <div
          className={
            !isMobile && "flex-none w-1/4 xl:w-1/4 sm:w-1/4 lg:w-1/4 "
          }>
          <LeftSideBar isMobile={isMobile} currentActiveTab='profile' />
        </div>
        <div className='flex-grow border-[#222222] sm:border-x'>
          {loadingUserInfo && (
            <div className='flex justify-center items-center mt-4 bg flex-col space-y-2 min-h-screen'>
              <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
            </div>
          )}

          {!loadingUserInfo && doesUserExits && (
            <>
              {isMobile && (
                <div className='fixed top-0 w-full'>
                  <Header />
                </div>
              )}
              <div className='border-b border-[#222222] pt-10 sm:pt-0'>
                <div className=' text-gray-200  '>
                  <div className='bg-[#1B1B24] h-20 sm:h-28'></div>
                  <div className='flex justify-center flex-col items-center'>
                    <img
                      src={currentUser.pfpUrl}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultPfp;
                      }}
                      className='h-24 w-24 rounded-full  -mt-10 '
                    />
                    <div className='flex flex-col items-center justify-center'>
                      <p className='text-white text-2xl font-bold mt-4'>
                        {currentUser.xDisplayName}
                      </p>
                      <p className='text-gray-400 text-sm font-thin'>
                        @{currentUser.xUsername}
                      </p>
                    </div>
                    <div className='flex items-center justify-center space-x-4 mt-2'>
                      <button
                        className='bg-[#1b1b24] hover:bg-[#13131a] text-gray-300 px-4 py-1 rounded-full font-semibold text-base'
                        onClick={() => {
                          handleBuyClick();
                        }}>
                        $
                        {Math.round(
                          currentUser.price * privyContextObject.ethPrice * 100
                        ) / 100}{" "}
                        <span className='text-green-500 text-base'>Buy</span>
                        <TradeModal
                          showModal={showModal}
                          setShowModal={setShowModal}
                          isBuy={true}
                          userObject={currentUser}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-gray-300  font-thin mt-4 text-center px-2'>
                    {currentUser.description &&
                      currentUser.description.split("\n").map((line, index) => (
                        <span key={index}>
                          {index > 0 && <br />}{" "}
                          {/* Add line break after the first line */}
                          <span className=''>{line}</span>
                        </span>
                      ))}
                  </p>
                </div>

                <div className='flex justify-center mt-4'>
                  <button
                    className='bg-[#1B1B24] text-white rounded-md px-4  hover:bg-[#262633] focus:outline-none  py-1  text-sm '
                    onClick={handleWalletClick}>
                    {currentUser.Address.slice(0, 12)}.....
                    {currentUser.Address.slice(-10)}
                  </button>
                </div>
                <div className='flex flex-wrap sm:flex-nowrap  space-x-2 mt-3 w-full justify-center items-center'>
                  <button className=' text-gray-400   py-3 rounded-md px-4 text-sm'>
                    Holders: {currentUser.holdersCount}
                  </button>

                  <button className=' text-gray-400   py-3 rounded-md px-4 text-sm'>
                    Holdings: {currentUser.holdsCount}
                  </button>

                  <button className=' text-gray-400   py-3 rounded-md px-4 text-sm'>
                    Key Supply: {Math.round((currentUser.supply * 10) / 10)}
                  </button>

                  <Tippy
                    className='text-gray-100 bg-[#242430] text-center px-3 py-2 rounded-md'
                    content={`This Creator earns ${currentUser.feePercent} of the transaction fees when their keys are bought or sold`}
                    placement='top'>
                    <button className=' text-gray-400   py-3 rounded-md px-4 text-sm'>
                      Founder Reward: {currentUser.feePercent}
                    </button>
                  </Tippy>
                </div>
              </div>
              <div className='flex shadow-md border-r border-[#08080B] '>
                {" "}
                <div className='w-full flex     bg-[#08080B]  l-0 r-0 -z-1'>
                  <button
                    className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                      navTab === "Posts" &&
                      " border-b-2  border-yellow-500 text-white"
                    }`}
                    onClick={() => handleNavChange("Posts")}>
                    Posts
                  </button>
                  <button
                    className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                      navTab === "Holders" &&
                      " border-b-2  border-yellow-500 text-white"
                    }`}
                    onClick={() => handleNavChange("Holders")}>
                    Holders
                  </button>
                  <button
                    className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] ${
                      navTab === "Holdings" &&
                      " border-b-2  border-yellow-500 text-white"
                    }`}
                    onClick={() => handleNavChange("Holdings")}>
                    Holdings
                  </button>
                </div>
              </div>
            </>
          )}

          {navTab === "Posts" && doesUserExits && (
            <div className='mt-2 mb-12 sm:mb-0'>
              {loadingPosts && !loadingUserInfo && (
                <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                  <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
                </div>
              )}

              {!loadingPosts && userPosts.length > 0 && (
                <InfiniteScroll
                  dataLength={userPosts.length}
                  next={getMoreUserPosts}
                  hasMore={hasMore}
                  loader={
                    <div className='flex justify-center items-center overflow-hidden mt-4 bg flex-col space-y-2'>
                      <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
                    </div>
                  }
                  endMessage={
                    <p className='text-gray-200 text-lg  pt-10 text-center bg-[#08080B]'>
                      You have seen it all
                    </p>
                  }>
                  {userPosts.map((postObject) => {
                    let postObjectTemp = postObject;
                    // add currentUser data in the postObject
                    postObjectTemp.xDisplayName = currentUser.xDisplayName;
                    postObjectTemp.xUsername = currentUser.xUsername;
                    postObjectTemp.pfpUrl = currentUser.pfpUrl;
                    postObjectTemp.price = currentUser.price;
                    postObjectTemp.Address = currentUser.Address;
                    postObjectTemp.CommentsCount = postObject.commentCount;
                    postObjectTemp.LikesCount = postObject.likes;

                    return (
                      <PostBox
                        postObject={postObjectTemp}
                        ethPrice={privyContextObject.ethPrice}
                      />
                    );
                  })}
                </InfiniteScroll>
              )}
            </div>
          )}

          {navTab === "Holders" && (
            <div className='mb-12 sm:mb-0'>
              {loadingHolders && (
                <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                  <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
                </div>
              )}
              {!loadingHolders && holdersList.length > 0 && (
                <div className='flex sm:justify-center sm:mx-2'>
                  <table className='w-full max-w-screen-lg  shadow-md rounded my-1'>
                    <thead>
                      <tr className='text-gray-200 text-lg mt-16 pb-4 '>
                        <th className='py-3 px-6 text-left text-gray-300 t text-sm font-semibold'>
                          User
                        </th>

                        <th className='py-3 px-6  text-gray-300 t text-sm font-semibold text-end'>
                          Keys
                        </th>

                        <th className='py-3 px-6  text-gray-300 t text-sm font-semibold text-end'>
                          Market Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 text-sm font-light'>
                      {holdersList.map((user) => {
                        return (
                          <HolderList
                            user={user}
                            isMobile={isMobile}
                            ethPrice={privyContextObject.ethPrice}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {holdersList.length === 0 && !loadingHolders && (
                <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                  <p className='text-gray-300 text-lg font-semibold'>
                    No holders found
                  </p>
                </div>
              )}
            </div>
          )}

          {navTab === "Holdings" && (
            <div className='mb-12 sm:mb-0'>
              {loadingHoldings && (
                <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                  <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
                </div>
              )}
              {!loadingHoldings && holdingsList.length > 0 && (
                <div className='flex sm:justify-center sm:mx-2'>
                  <table className='w-full max-w-screen-lg  shadow-md rounded my-1'>
                    <thead>
                      <tr className='text-gray-200 text-lg mt-16 pb-4 '>
                        <th className='py-3 px-6 text-left text-gray-300 t text-sm font-semibold'>
                          User
                        </th>

                        <th className='py-3 px-6  text-gray-300 t text-sm font-semibold text-end'>
                          Keys
                        </th>

                        <th className='py-3 px-6  text-gray-300 t text-sm font-semibold text-end'>
                          Market Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className='text-gray-600 text-sm font-light'>
                      {holdingsList.map((user) => {
                        return (
                          <HoldingList
                            user={user}
                            isMobile={isMobile}
                            ethPrice={privyContextObject.ethPrice}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}
              {holdingsList.length === 0 && !loadingHoldings && (
                <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                  <p className='text-gray-300 text-lg font-semibold'>
                    No holders found
                  </p>
                </div>
              )}
            </div>
          )}

          {!doesUserExits && !loadingUserInfo && (
            <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
              <p className='text-gray-300 text-lg font-semibold'>
                User not found
              </p>
            </div>
          )}
        </div>

        {!isMobile && (
          <div className='flex-none xl:w-1/4 sm:w-1/4 lg:w-1/3 '>
            {" "}
            <RightSideBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
