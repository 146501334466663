import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import toast from "react-hot-toast";
function DepositModal({ showModal, setShowModal, address }) {
  const handleWalletClick = () => {
    // console.log("clicked");
    //copy the address to clipboard
    navigator.clipboard.writeText(address);

    toast.success("Address copied to clipboard", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  };

  return (
    <div className=''>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl border border-[#040405] text-left align-middle shadow-xl transition-all bg-[#1b1b24]'>
                  <div className='flex justify-end space-x-4 py-3 px-6 fixed -right-6 -top-4 z-50 '>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-l-lg border-none px-2 py-2 text-sm font-medium  focus:outline-none bg-slate-800 hover:bg-slate-900 text-white'
                      onClick={() => setShowModal(false)}>
                      <MdOutlineClose size={24} />
                    </button>
                  </div>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-xl text-center my-4 font-semibold text-gray-100'>
                    Deposit ETH
                  </Dialog.Title>
                  <div className='mt-6 px-6 text-gray-100'>
                    <p>
                      FrenHive is built on Blast. Deposit Blast ETH to the below
                      address:
                    </p>
                    <button
                      className='bg-gray-800 text-white rounded-md px-4 my-6  hover:bg-gray-700 focus:outline-none py-1  text-xs sm:text-base '
                      onClick={handleWalletClick}>
                      {address}
                    </button>
                    <div className='flex justify-center mx-auto my-2'>
                      <a
                        href='https://app.rhino.fi/bridge?token=ETH&chainOut=BLAST&chain=BASE'
                        target='_blank'>
                        <button className='bg-[#EAB308] text-black rounded-md px-4 py-2 mx-auto focus:outline-none text-xs sm:text-base'>
                          Bridge to Blast
                        </button>
                      </a>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default DepositModal;
