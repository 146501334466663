import React, { useState } from "react";
import WalletSection from "./WalletSection";
import PointsSection from "./PointsSection";
import ReferralsSection from "./ReferralsSection";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/Header";
export default function WalletBox({ isMobile }) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");
  const navigate = useNavigate();


  const [currentTab, setCurrentTab] = useState(!tab ? "wallet" : tab);
  function handleTabChange(tab) {
    setCurrentTab(tab);
    navigate(`/wallet?tab=${tab}`);

    
    
  }
  return (
    <div className={`sm:border-x border-[#222222] min-h-screen w-full`}>
        {isMobile && <div className="fixed top-0 w-full"> <Header/> </div> } 
      <div className={`flex shadow-md sm:pt-14`}>
        {" "}
        <div className='w-full flex justify-between   bg-[#08080B] pt-20 sm:pt-0'>
          <button
            className={`text-gray-400 font-bold px-6 py-4 text-xl  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] w-full ${
              currentTab === "wallet" &&
              " border-b-2  border-yellow-500 text-white"
            }`}
            onClick={() => handleTabChange("wallet")}>
            Wallet
          </button>
          <button
            className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3  sm:hover:bg-[#1b1b24] w-full ${
              currentTab === "points" &&
              " border-b-2  border-yellow-500 text-white"
            }`}
            onClick={() => handleTabChange("points")}>
            Points
          </button>
          <button
            className={`text-gray-400 font-bold px-6 py-4  flex justify-center items-center space-x-3 sm:hover:bg-[#1b1b24] w-full ${
              currentTab === "referrals" &&
              " border-b-2  border-yellow-500 text-white"
            }`}
            onClick={() => handleTabChange("referrals")}>
            Referrals
          </button>
        </div>
      </div>

      {currentTab === "wallet" && (
        <>
          <WalletSection isMobile={isMobile} />
        </>
      )}

      {currentTab === "points" && (
        <>
          <PointsSection isMobile={isMobile} />
        </>
      )}

      {currentTab === "referrals" && (
        <>
          <ReferralsSection isMobile={isMobile} />
        </>
      )}
    </div>
  );
}
