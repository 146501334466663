import React from "react";

export default function Footer() {
  return (
    <footer
      className={`text-center lg:text-left  bg-[#08080B] text-white py-10 h-80 relative`}>
      <div className='w-full px-2 sm:w-3/5 mx-auto'>
        <div>
          <p className='text-[#e5e74c] text-xl ml-2 font-bold'>Team</p>
          <div className='flex flex-col mt-4 '>
            <a
              className='flex space-x-3 items-center'
              href='https://twitter.com/itsaditya_xyz'
              target='_blank'>
              <img
                src='https://images.bitclout.com/7561dce8a78e266e33ce4b3340f8b60ce57e2a2dcb17aa22fe2fc0eac5c14432.webp'
                className='h-20 w-20 rounded-full'
              />

              <div>
                <p className='text-[#BB0168] text-xl font-semibold'>
                  {" "}
         Aditya
                </p>
                <p className='text-gray-600 text-xl italic font-semibold'>
                  {" "}
                 Co-founder and frontend guy.
                </p>
              </div>
            </a>
          </div>
          <div className='flex flex-col mt-3'>
            <a
              className='flex space-x-3 items-center'
              href='https://twitter.com/0xkuririn'
              target='_blank'>
              <img
                src='https://images.deso.org/2174bbad817f838cb553e7fe2d925ceeda62f24efbd43c2b08fc234660909428.webp'
                className='h-20 w-20 rounded-full'
              />

              <div>
                <p className='text-[#BB0168] text-xl font-semibold'>
                  {" "}
                  Kuririn
                </p>
                <p className='text-gray-600 text-xl italic font-semibold'>
                  {" "}
                  Co-founder, Blockchain dev.
                </p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
