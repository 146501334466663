import React, { useContext, useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { FaEthereum } from "react-icons/fa";
import KeyComponent from "./KeyComponent";
import PrivyContext from "../../../AppStates/PrivyContext";
import API from "../../../API/API";
import { usePrivy } from "@privy-io/react-auth";
import toast from "react-hot-toast";
import DepositModal from "../../../components/Modals/DepositModal";
import WithdrawModal from "../../../components/Modals/WithdrawModal";
export default function WalletSection({ isMobile }) {
  const privyContextObject = useContext(PrivyContext);
  const { exportWallet, authenticated, user, getAccessToken } = usePrivy();
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const handleWalletClick = () => {
    navigator.clipboard.writeText(privyContextObject.userData.Address);

    toast.success("Address copied to clipboard", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  };

  async function getWalletBalance() {
    try {
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);

      const result = await ApiObject.getETHBalance(user.twitter.username);
      if (result) {
        console.log(result);
        privyContextObject.updateEthBalance(
          Math.round(result.balance * 10000) / 10000
        );
      }

      const result2 = await ApiObject.getHoldingsOfUser(user.twitter.username);
      if (result2) {
        privyContextObject.updateKeysHeld(result2.holdings);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (privyContextObject.hasLoggedIn) {
      getWalletBalance();
    }
  }, [privyContextObject.userData]);

  return (
    <div className='w-full '>
      {!privyContextObject.hasLoggedIn ? (
        <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
          <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
        </div>
      ) : (
        <div>
          <DepositModal
            showModal={showDepositModal}
            setShowModal={setShowDepositModal}
            address={privyContextObject.userData.Address}
          />
          <WithdrawModal
            showModal={showWithdrawModal}
            setShowModal={setShowWithdrawModal}
          />
          <div className='bg-gradient-to-b from-[#1B1B24] to-[#08080B] mx-4 my-4 rounded-lg'>
            <div className='flex flex-col items-center'>
              <div className='flex flex-col items-center'>
                <p className='text-base  text-gray-300 mt-10'>Total Balance</p>
                <div className='flex mt-4 items-center'>
                  <p className='text-2xl font-bold text-gray-200 mt-2'>
                    {" "}
                    {privyContextObject.ethBalance}
                  </p>
                  <span className='text-gray-400'>
                    <FaEthereum size={30} />
                  </span>
                </div>
              </div>
              <p className='text-lg font-bold text-gray-300 -ml-4'>{`~ $${
                Math.round(privyContextObject.ethBalance * 2600 * 10) / 10
              }`}</p>
            </div>

            <div className='flex flex-col items-center justify-center space-y-2 mt-10'>
              <button
                className='bg-gray-800 text-white rounded-md px-4  hover:bg-gray-700 focus:outline-none focus:ring-2   py-1  text-base '
                onClick={handleWalletClick}>
                {privyContextObject.userData.Address.slice(0, 12)}........
                {privyContextObject.userData.Address.slice(-10)}
              </button>

              <div className='flex space-x-2 mt-4 w-full justify-center items-center'>
                <button className='bg-[#1B1B24] text-gray-400  py-3 rounded-md px-4 text-sm'>
                  Holders: {privyContextObject.userData.holdersCount}
                </button>

                <button className='bg-[#1B1B24] text-gray-400  py-3 rounded-md px-4 text-sm'>
                  Holdings: {privyContextObject.userData.holdsCount}
                </button>
              </div>
              <div className='flex space-x-2 mt-4 w-full justify-center items-center'>
                <button
                  className='bg-gray-800 text-white py-3 rounded-md px-4 text-sm hover:bg-gray-700 focus:outline-none   '
                  onClick={() => {
                    setShowDepositModal(true);
                  }}>
                  Deposit
                </button>
                <button
                  className='bg-gray-800 text-white py-3 rounded-md px-4 text-sm hover:bg-gray-700 focus:outline-none '
                  onClick={() => {
                    setShowWithdrawModal(true);
                  }}>
                  Withdraw
                </button>
                <button
                  className=' px-4 t bg-gray-800 text-white py-3 rounded-md text-sm hover:bg-gray-700 focus:outline-none '
                  onClick={() => exportWallet()}>
                  <CiExport size={20} className='text-gray-100' />
                </button>
              </div>
            </div>
          </div>
          <p className='text-gray-200 text-lg mt-16 sm:pb-4 mx-4'>
            Keys Purchased
          </p>
          <div className='flex sm:justify-center sm:mx-2'>
            <table className='w-full max-w-screen-lg  shadow-md rounded sm:my-6'>
              <thead>
                <tr className='text-gray-200 text-lg mt-16 pb-4 '>
                  <th className='py-3 px-6 text-left text-gray-200 t text-sm font-thin'>
                    User
                  </th>
                  {!isMobile && (
                    <th className='py-3 px-6 text-left text-gray-200 t text-sm font-thin'>
                      Keys
                    </th>
                  )}
                  {!isMobile && (
                    <th className='py-3 px-6 text-left text-gray-200 t text-sm font-thin'>
                      Value
                    </th>
                  )}
                  {isMobile && (
                    <th className='py-3 px-6  text-gray-200 t text-sm font-thin text-end'>
                      Keys x Value
                    </th>
                  )}

                  <th className='py-3 px-6  text-gray-200 t text-sm font-thin text-end'>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 text-sm font-light'>
                {privyContextObject.keysHeld && privyContextObject.keysHeld.map((user) => {
                  return (
                    <KeyComponent
                      user={user}
                      isMobile={isMobile}
                      ethPrice={privyContextObject.ethPrice}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
