import React, { useState } from "react";
import TradeModal from "../Modals/TradeModal";
import { Link } from "react-router-dom";
import defaultPfp from "../../assets/default-profile-pic.png";
export default function UserBox({ user, currentUserObject, ethPrice }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className='flex items-center justify-start  w-full'>
      <Link to={`/u/${user.username}`} className='w-full flex'>
        <img
          src={user.pfp}
          className='h-14 w-14 rounded-full'
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultPfp;
          }}
        />
        <Link to={`/u/${user.username}`} className='ml-2 '>
          <p className='text-gray-300 text-base font-semibold sm:hover:underline overflow-hidden'>
            {user.displayName}
          </p>
          <p className='text-gray-400 text-sm font-thin'>@{user.username}</p>
        </Link>
      </Link>

      <div className='flex-grow  justify-end items-end flex w-full'>
        <button
          className='bg-[#13131a] hover:bg-[#0c0c11] text-gray-300 px-3 py-1 rounded-full text-sm'
          onClick={() => {
            setShowModal(true);
          }}>
          ${Math.round(user.price * ethPrice * 100) / 100}{" "}
          <span className='text-green-500 text-base'>Buy</span>
          <TradeModal
            showModal={showModal}
            setShowModal={setShowModal}
            isBuy={true}
            userObject={currentUserObject}
          />
        </button>
      </div>
    </div>
  );
}
