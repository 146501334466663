import React, { useState, useRef, useContext } from "react";
import PrivyContext from "../../../AppStates/PrivyContext";
import defaultPfp from "../../../assets/default-profile-pic.png";
import API from "../../../API/API";
import toast from "react-hot-toast";
import { usePrivy } from "@privy-io/react-auth";
export default function CreatePost() {
  const privyContextObject = useContext(PrivyContext);
  const [postBody, setPostBody] = useState("");
  const textareaRef = useRef(null);

  const { getAccessToken } = usePrivy();

  const [creatingPost, setCreatingPost] = useState(false);
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto to recalculate
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the new height
    }
  };

  const handleInputChange = (e) => {
    setPostBody(e.target.value);
    adjustTextareaHeight();
  };

  const createPost = async () => {
    if (creatingPost) {
      return;
    }
 
    if (privyContextObject.hasLoggedIn) {
      if (postBody.length === 0) {
        toast.error("Post cannot be empty", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
        return;
      }
      setCreatingPost(true);
      const loadingToast = toast.loading("Creating Post", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);
    
      const result = await ApiObject.createPost(postBody);
      console.log(result);

      // close the loading toast
      toast.dismiss(loadingToast);

      if (result.success) {
        toast.success("Post created successfully", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
        setPostBody("");
      }
      else{
        toast.error("Error creating post", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
      }

      setCreatingPost(false);
    } else {
      toast.error("You are logged in.", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
    }
  };
  return (
    <div className='bg-[#1b1b24] pt-4 px-2 border-b-2 border-[#222222]'>
      <div className='flex'>
        <img
          src={
            Object.keys(privyContextObject.userData).length === 0
              ? defaultPfp
              : privyContextObject.userData.pfpUrl
          }
          className='h-12 w-12 rounded-full'
        />
        <textarea
          ref={textareaRef}
          className='w-full  bg-[#1b1b24] outline-none   text-white rounded-xl p-4 resize-none min-h-20 max-h-60'
          style={{
            scrollbarWidth: "thin",
            scrollbarColor: "#4B5563 #1F2937",
          }}
          placeholder="Charts? Where we are going we don't need charts "
          value={postBody}
          onChange={handleInputChange}
        />
      </div>
      <div className='flex justify-end pb-2'>
        <button
          className='bg-yellow-500 hover:bg-yellow-600 text-black rounded-xl px-5 py-2 mt-2'
          onClick={createPost}>
          Post
        </button>
      </div>
    </div>
  );
}
