import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./page/Landing/Landing";
import MainApp from "./page/Home/MainApp/MainApp";
import Wallet from "./page/Wallet/Wallet";
import User from "./page/User/User";
import PageNotFound from "./page/PageNotFound/PageNotFound";
import { useEffect, useState, useContext } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useWallets } from "@privy-io/react-auth";
import Login from "./page/Login/Login";
import Notifications from "./page/Notifications/Notifications";
import PrivyContext from "./AppStates/PrivyContext";
import Settings from "./page/Settings/Settings";
import API from "./API/API";
import Posts from "./page/Posts/Posts";
import Messages from "./page/Messages.jsx/Messages";

function App() {
  const privyContextObject = useContext(PrivyContext);
  const { ready, authenticated, user, getAccessToken } = usePrivy();

  const { wallets } = useWallets();
  const [isFetchingUserInfo, setIsFetchingUserInfo] = useState(false);
  async function initializeCurrentUser() {
    const accessToken = await getAccessToken();
    const api = new API(accessToken);
    const userData = await api.getUserInfo(user.twitter.username);
    privyContextObject.updateUserData(userData);
    privyContextObject.updateHasLoggedIn(true);
    console.log("Initialized user data!");
  }

  async function initializeETHPrice() {
    try {
      const getURL = "https://api.coinbase.com/v2/prices/ETH-USD/spot";
      const response = await fetch(getURL);
      const data = await response.json();
      const ethPrice = data.data.amount;
      privyContextObject.updateEthPrice(ethPrice);
    } catch (error) {
      console.log(error);
    }
  }

  async function initializeTrendingUser() {
    try {
      if (privyContextObject.trendingUsers.length === 0) {
        const ApiObject = new API();
        var trendingUsers = await ApiObject.getTopUsers(8);
        //loop thourhg trendingUsers and remove the object if username is null
        trendingUsers = trendingUsers.filter((user) => user.username !== null);
      
  
        privyContextObject.updateTrendingUsers(trendingUsers);
      }
    } catch (error) {
      console.log(error);
    }
  }
  // this use effect to fetch user info and auth status. very complex shit. don't touch.
  useEffect(() => {
    if (!ready) {
      console.log("privy not laoded yet...");
      return;
    } else {
      const userData = privyContextObject.userData;
      console.log(userData);
      if (authenticated && !isFetchingUserInfo) {
        setIsFetchingUserInfo(true);
        //check if wallet and twitter is linked
        if (
          typeof user.wallet !== "undefined" &&
          typeof user.twitter !== "undefined"
        ) {
          console.log("wallet and twitter is linked");

          initializeCurrentUser();
        }
      } else {
        console.log(
          "either user not authenticated or already fetched user info..."
        );
      }
    }
  }, [wallets]);

  useEffect(() => {
    initializeETHPrice();
    initializeTrendingUser();
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/home" element={<MainApp />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/u/:username" element={<User />} />
          <Route path="/login" element={<Login />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/settings" element={<Settings />} />
          <Route path = "/posts/:postID" element={<Posts/>} />
          <Route path = "/messages" element={<Messages/>} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
