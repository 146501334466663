import { Dialog, Transition } from "@headlessui/react";
import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import defaultPfp from "../../assets/default-profile-pic.png";
import { MdOutlineClose } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import API from "../../API/API";
import { usePrivy } from "@privy-io/react-auth";
import KeyPriceTag from "../FeedBox/subComponents/KeyPriceTag";
import PrivyContext from "../../AppStates/PrivyContext";
import { Link } from "react-router-dom";
function SearchModal({ showModal, setShowModal }) {
  const privyContextObject = useContext(PrivyContext);
  const [username, setUsername] = useState("");
  const { getAccessToken } = usePrivy();
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [hasResult, setHasResult] = useState(false);
  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  async function searchUser(query) {
    try {
      setLoading(true);
      const accessToken = await getAccessToken();
      const ApiObject = new API(accessToken);
      const result = await ApiObject.searchUsers(query);
      if (result.length === 0) {
        setSearchResult([]);
        setHasResult(false);
      } else {
        //store only first 6 results
        console.log(result);
        setSearchResult(result.slice(0, 6));
        setHasResult(true);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  const debouncedSearch = useCallback(
    debounce((query) => {
      // Call your API search function here
      searchUser(query);
    }, 800),
    []
  ); // 800ms delay

  useEffect(() => {
    if (username) {
      debouncedSearch(username);
    }
  }, [username]);

  return (
    <div className=''>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl border border-[#040405] text-left align-middle shadow-xl transition-all bg-[#1b1b24]'>
                  <div className='flex justify-end space-x-4 py-3 px-6 fixed -right-6 -top-4 z-50 '>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-l-lg border-none px-2 py-2 text-sm font-medium  focus:outline-none bg-slate-800 hover:bg-slate-900 text-white'
                      onClick={() => setShowModal(false)}>
                      <MdOutlineClose size={24} />
                    </button>
                  </div>
                  <Dialog.Title
                    as='h3'
                    className='text-lg leading-6 font-xl text-center my-4 font-semibold text-gray-100'>
                    Search Users
                  </Dialog.Title>
                  <div className='mt-4 '>
                    <div className='px-6'>
                      <div className='flex justify-between items-center w-full pb-10 '>
                        <div className='  bg-slate-800 rounded-l-xl px-3 py-4  focus:outline-none text-gray-300 '>
                          <FaSearch className='text-gray-500' />
                        </div>
                        <input
                          type='text'
                          placeholder='Search'
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          className='bg-slate-800 rounded-r-xl px-3 py-3 w-full focus:outline-none text-gray-300'
                        />
                      </div>
                    </div>
                    {loading && (
                      <div className='flex justify-center items-center mt-4 bg flex-col space-y-2 py-2 '>
                        <div className='animate-spin rounded-full h-12 w-12 border-b-2 border-yellow-500'></div>
                      </div>
                    )}
                    {!loading &&
                      searchResult.map((user) => {
                        return (
                          <Link
                            className='flex items-center justify-start  w-full bg-[#14141b] px-2 py-2 border-b border-[#222222]'
                            to={`/u/${user.xUsername}`}>
                            <img
                              src={user.pfpUrl}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultPfp;
                              }}
                              className='h-14 w-14 rounded-full'
                            />

                            <div className='ml-2'>
                              <p className='text-gray-300 text-base font-semibold sm:hover:underline'>
                                {user.xDisplayName}
                              </p>
                              <p className='text-gray-400 text-sm font-thin'>
                                @{user.xUsername}
                              </p>
                            </div>
                          </Link>
                        );
                      })}

                    {!loading && !hasResult && (
                      <div className='flex justify-center items-center mt-1 bg flex-col space-y-2 pb-4'>
                        <p className='text-gray-300 text-base font-semibold'>
                          No User Found
                        </p>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default SearchModal;
