import React, { useEffect, useContext, useState } from "react";
import { FaCopy } from "react-icons/fa6";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../../AppStates/PrivyContext";
import API from "../../../API/API";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
export default function ReferralsSection({ isMobile }) {
  const { getAccessToken } = usePrivy();
  const privyContextObject = useContext(PrivyContext);
  const [loadingReferralCodes, setLoadingReferralCodes] = useState(
    privyContextObject.referralData.length===0
  );

  async function getReferralCodes() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const result = await ApiObject.getInviteCodesOfSelf();
    if (!result || result.length === 0) {
      toast.error("Failed to fetch referral codes", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      return;
    }
    if (result.length > 0 && result) {
      privyContextObject.updateReferralData(result);
    }
  }

  async function getUsersInvited() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const result = await ApiObject.getUserReferredBySelf();
    console.log(result);
    if(result.referrals){
      privyContextObject.updateReferralEarnings(result.referrals);
    }
   
    setLoadingReferralCodes(false);
  }

  function handleCodeCopy(code) {
    // copy the code to clipboard
    navigator.clipboard.writeText(code);

    toast.success("Referral Code Copied!", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  }

  useEffect(() => {
    if (privyContextObject.hasLoggedIn) {
      try {
        getReferralCodes();
        getUsersInvited();
      } catch (error) {
        console.log(error);
      }
    }
  }, [privyContextObject.userData]);
  return (
    <div className='w-full '>
      <div className='bg-gradient-to-b from-[#1B1B24] to-[#08080B] mx-4 my-4 rounded-lg'>
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-center'>
            <p className='text-base  text-gray-300 mt-10 px-4'>
              Invite new creators and earn 100% of their fee for first 100
              days!
            </p>
            {loadingReferralCodes && (
              <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
              </div>
            )}

            {!loadingReferralCodes && privyContextObject.referralData && (
              <div className='mt-3'>
                {privyContextObject.referralData.map((referral, index) => {
                  if (referral.Used != 0) return;
                  return (
                    <div
                      className='flex  items-center justify-end mt-1'
                      key={index}>
                      <p className='text-gray-200 text-base font-semibold'>
                        {" "}
                        fh-{referral.Code}
                      </p>
                      <button
                        className='ml-2 text-gray-300'
                        onClick={() => handleCodeCopy(`fh-${referral.Code}`)}>
                        <FaCopy size={20} />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      <p className='text-gray-200 text-lg mt-16 pb-4 mx-5'>
        Users Invited by you
      </p>
      <div className='flex sm:justify-center sm:mx-2'>
        <table className='w-full max-w-screen-lg  shadow-md rounded sm:my-6'>
          <thead>
            <tr className='text-gray-200 text-lg mt-16 pb-4 '>
              <th className='py-3 px-6 text-left text-gray-200 t text-sm font-thin'>
                User
              </th>

              <th className='py-3 px-6  text-gray-200 t text-sm font-thin text-end'>
                Trading fee earned
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-600 text-sm font-light'>
            {privyContextObject.referralEarnings.map((user) => {
              return (
                <tr className=' w-full border-b border-[#222222] pb-2 '>
                  <td className='py-3 px-6 '>
                    <Link to={`/u/${user.username}`}>
                      <div className='flex items-center space-x-2 '>
                        <img
                          src={user.pfp}
                          alt='pfp'
                          className='w-8 h-8 rounded-full'
                        />
                        <div className=''>
                          <p className='text-gray-300 text-base font-semibold sm:hover:underline'>
                            {user.displayName}
                          </p>
                          <p className='text-gray-400 text-sm font-thin'>
                            @{user.username}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </td>

                  <td className='py-3 px-6 text-end'>
                    <p className='text-gray-400 text-sm font-thin'>
                      $
                      {Math.round(
                        user.feeEarned * privyContextObject.ethPrice * 100
                      ) / 100}
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
