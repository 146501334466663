import { createContext } from "react";

// Using this we can fetch memPolList, updateMemPoolState, appState, exchangeRates from any component
// this helps us not to ping the getappstate and getexchangerate api every time we need to use it
const PrivyContext = createContext({
  userData: {},
  hasLoggedIn: false,
  updateUserData: () => {},
  updateHasLoggedIn: () => {},

  referralData: [],
  updateReferralData: () => {},

  ethBalance: 0,
  updateEthBalance: ()=>{},

  ethPrice: 2500,
  updateEthPrice: ()=>{},

  hiveFeed: [],
  updateHiveFeed: ()=>{},

  trades: [],
  updateTrades: ()=>{},

  keysHeld :[],
  updateKeysHeld: ()=>{},

  referralEarnings: [],
  updateReferralEarnings: ()=>{},

  trendingUsers: [],
  updateTrendingUsers: ()=>{},

  notifications: [],
  updateNotifications: ()=>{},

  newUsers: [],
  updateNewUsers: ()=>{},

  newFeed: [],
  updateNewFeed: ()=>{},

  postTab: "hot",
  updatePostTab: ()=>{},

  points: 0,
  updatePoints: ()=>{},

  pointsLeaderboards: [],
  updatePointsLeaderboards: ()=>{},
  
  
});

export default PrivyContext;
