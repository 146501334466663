import React from "react";
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import PostBox from "../../../components/FeedBox/subComponents/PostBox";
import CommentBox from "./CommentBox";
import { RiCoinFill } from "react-icons/ri";
import { Link } from "react-router-dom";
export default function NotificationRender({ notification , selfData}) {
  return (
    <div className='text-gray-200'>
      {notification.actionType === "like" && (
        <div className='flex justify-between items-center border-b border-[#222222] py-3 px-3 pt-2 hover:cursor-pointer sm:hover:bg-[#101016]'>
          <div className='flex items-center space-x-3'>
            <img
              src={notification.actionerProfile.pfpURL}
              alt=''
              className='w-10 h-10 rounded-full'
            />
            <p>
              <span className='font-bold'>
                {notification.actionerProfile.name}
              </span>{" "}
              <span className='font-thin'>voted </span>{" "}
              <span className='text-gray-400 font-thin italic '>
                {notification.extraData.postContent}
              </span>
            </p>
          </div>
          <div>
            <BiSolidUpvote size={20} className='text-green-500' />
          </div>
        </div>
      )}
      {(notification.actionType === "buy" ||
        notification.actionType === "sell") && (
        <div className='flex justify-between items-center border-b border-[#222222] py-3 px-3 pt-2 hover:cursor-pointer sm:hover:bg-[#101016]'>
          <div className='flex items-center space-x-3'>
            <img
              src={notification.actionerProfile.pfpURL}
              alt=''
              className='w-10 h-10 rounded-full'
            />
            <p>
              <Link
                className='font-bold sm:hover:underline'
                to={`/u/${notification.actionerProfile.username}`}>
                {notification.actionerProfile.username}
              </Link>{" "}
              <span
                className={`font-extralight ${
                  notification.actionType === "buy"
                    ? "text-green-400"
                    : "text-red-400"
                }`}>
                {notification.actionType === "buy" ? "bought" : "sold"}{" "}
              </span>{" "}
              <span className='text-gray-100 font-bold  '>
                {notification.extraData.shareCountTraded} keys{" "}
                <span className='text-gray-400 font-thin'>of {selfData.xUsername}</span>
              </span>
            </p>
          </div>
          <div>
            {notification.actionType === "buy" ? (
              "🎉"
            ) : (
              <RiCoinFill size={18} className='text-yellow-500' />
            )}
          </div>
        </div>
      )}
      {notification.actionType === "comment" && (
        <div className='border-b border-[#222222]  '>
          <CommentBox commentObject={notification} />
        </div>
      )}
    </div>
  );
}
