import React, { useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../../AppStates/PrivyContext";
import { useContext } from "react";
import API from "../../../API/API";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";
import { BiQuestionMark } from "react-icons/bi";
import FrenHiveContract from "../../../API/FrenHiveContract";
import Header from "../../../components/Header";
export default function ProfileSettings({ isMobile }) {
  const privyContextObject = useContext(PrivyContext);
  const { getAccessToken, sendTransaction } = usePrivy();
  const [description, setDescription] = useState("");
  const [updatingProfile, setUpdatingProfile] = useState(false);

  const [fr, setFr] = useState(0);

  useEffect(() => {
    setDescription(privyContextObject.userData.description);
    try {
      setFr(privyContextObject.userData.feePercent.toString().charAt(0));
    } catch (e) {
      setFr(5);
    }
  }, [privyContextObject.userData]);

  const updateProfile = async () => {
    if (updatingProfile) return;
    setUpdatingProfile(true);
    const loadingToast = toast.loading("Updating Profile", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });

    try {
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);
      const response = await ApiObject.updateUserDescription(description);
      console.log(response);
      toast.dismiss(loadingToast);
      if (response) {
        toast.success("Profile updated successfully", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
      }
    } catch (error) {
      console.log(error);
      toast.dismiss(loadingToast);
      toast.error("Profile update failed", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
    }
  };

  const handleFrUpdate = async () => {
    if (fr > 5) {
      toast.error("Founder Reward Percentage cannot be more than 5%", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      return;
    }
    if (fr < 0) {
      toast.error("Founder Reward Percentage cannot be less than 0%", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      return;
    }
    if (fr === privyContextObject.userData.feePercent) {
      toast.error("No changes made", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      return;
    }

    const contractObject = new FrenHiveContract(sendTransaction);
    // 5 * 10**16 is 5% so convert finalValueAccordingly
    const finalValue = parseFloat(fr) * 10 ** 16;
    const response = contractObject.updateFR(
      privyContextObject.userData.Address,
      finalValue
    );

    // update the fr
  };

  return (
    <div>
      {isMobile && <Header />}
      {privyContextObject.hasLoggedIn && privyContextObject.userData && (
        <div className='pb-20'>
          <div className=''>
            <div className='w-full border-b border-[#222222]'>
              <h1 className='text-2xl font-bold text-gray-100 border-b border-[#222222] px-4 py-4'>
                Profile Settings
              </h1>
            </div>

            <div className='mt-6 flex justify-center flex-col items-center'>
              <div>
                <img
                  src={privyContextObject.userData.pfpUrl}
                  alt='profile'
                  className='h-20 w-20 rounded-full'
                />
              </div>

              <div className='sm:w-4/5 px-3 my-2 w-full'>
                <p className='text-gray-100 my-2'>Username</p>
                <input
                  type='text'
                  value={privyContextObject.userData.xUsername}
                  className='text-gray-100 bg-[#1B1B24] border-none outline-none w-full h-10 rounded-md px-2 py-1'
                />
                <p className='text-gray-100  mt-3 my-2'>Description</p>
                <textarea
                  value={description}
                  placeholder='Tell us about yourself...'
                  onChange={(e) => setDescription(e.target.value)}
                  className='text-gray-100 bg-[#1B1B24] outline-none w-full h-40 rounded-md my-2 px-2 resize-none py-1'
                />
              </div>
              <button
                className='bg-yellow-500 hover:bg-yellow-600 text-black rounded-xl px-5 py-2 mt-2'
                onClick={updateProfile}>
                Update Profile
              </button>
            </div>
          </div>
          <div className='mt-4 sm:w-4/5 px-3 my-2 mx-auto'>
            <Tippy
              className='text-gray-100 bg-[#242430] px-2 py-2 rounded-md'
              content='Founder Reward Percentage is the percentage of the buy/sell transaction of the key that goes to the user. It can be set between 0% and 5%.'
              placement='top'>
              <span className='flex items-center'>
                <p className='text-gray-100 my-2'>Founder Reward Percentage</p>
                <span className='bg-[#1B1B24] w-7 h-7  rounded-full flex justify-center items-center cursor-pointer ml-1'>
                  <BiQuestionMark
                    className='text-gray-100 font-bold'
                    size={16}
                  />
                </span>
              </span>
            </Tippy>

            <div className='flex flex-col items-center'>
              <input
                type='number'
                value={fr}
                onChange={(e) => setFr(e.target.value)}
                className='text-gray-100 bg-[#1B1B24] border-none outline-none w-full h-10 rounded-md px-2 py-1'
              />
              <button
                className='hover:bg-yellow-500 border-yellow-500 border text-gray-200 hover:text-gray-800 rounded-xl px-5 py-2 mt-2 mx-auto'
                onClick={() => {
                  handleFrUpdate();
                }}>
                Update Founder Reward
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
