import Web3 from "web3";
import ABI from "./ABI";
const RPC =
  "https://proportionate-hardworking-gas.blast-mainnet.quiknode.pro/91566e53cb7e1c8fb5d94c3103d32bb6dfd9ab0c/";
const CONTRACT_ADDRESS = "0xcac4501c7bab6e758551af65ce071ed021215085";
const GAS_LIMIT = 150000;
const GAS_PRICE = 0.09 * 1e9;

class FrenHiveContract {
  // this sendTransaction Object is coming from privy that is responsible for doing the txn. It is passed from TradeModal.jsx for example.
  constructor(sendTransaction) {
    this.sendTransaction = sendTransaction;
  }
  async handleBuyShares(selfAddress, amount, subjectAddress, value) {
    try {
      // Connect to the Ethereum network using the RPC URL
      const web3 = new Web3(RPC);

      // Get the contract ABI
      const contractABI = ABI;

      // Create a contract instance
      const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

      // Call the buyShares function on the contract
      const data = contract.methods
        .buyShares(subjectAddress, amount)
        .encodeABI();

      const unsignedTx = {
        to: CONTRACT_ADDRESS,
        data: data,
        gas: GAS_LIMIT,
        from: selfAddress,
        value,
        gasPrice: GAS_PRICE,
      };
      try {
        const response = await this.sendTransaction(unsignedTx);
        return response;
      } catch (err) {
        console.log("error happened while sending txn");
        console.log(err);
      }

      // Optionally, you can handle the response or update the UI accordingly
      console.log(data);
    } catch (error) {
      // Handle errors
      console.error("Error calling buyShares function:", error);
    }
  }

  //i could have overloaded above function but my brain hurts when i use it anymore.
  async handleFirstBuyShares(selfAddress, amount, value, referralAddress) {
    try {
      const web3 = new Web3(RPC);
      const contractABI = ABI;
      const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

      // Call the buyShares function on the contract
      console.log(selfAddress, amount, referralAddress);
      const data = contract.methods["buyShares(address,uint256,address)"](
        selfAddress,
        amount,
        referralAddress
      ).encodeABI();
      const unsignedTx = {
        to: CONTRACT_ADDRESS,
        data: data,
        gas: 2 * GAS_LIMIT,
        from: selfAddress,
        value,
        gasPrice: GAS_PRICE,
      };
      try {
        const response = await this.sendTransaction(unsignedTx);
        console.log(response);
        return response;
      } catch (err) {
        console.log("error happened while sending txn");
        console.log(err);
      }

      // Optionally, you can handle the response or update the UI accordingly
      console.log(data);
    } catch (error) {
      // Handle errors
      console.error("Error calling buyShares function:", error);
    }
  }

  async handleSellShares(selfAddress, amount, subjectAddress) {
    try {
      const web3 = new Web3(RPC);
      const contractABI = ABI;
      const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

      // Call the sellShares function on the contract
      console.log(amount);
      const data = contract.methods
        .sellShares(subjectAddress, amount)
        .encodeABI();

      const unsignedTx = {
        to: CONTRACT_ADDRESS,
        data: data,
        gas: GAS_LIMIT,
        from: selfAddress,
        gasPrice: GAS_PRICE,
      };
      try {
        const response = await this.sendTransaction(unsignedTx);
        console.log(response);
        return response;
      } catch (err) {
        console.log("error happened while sending txn");
        console.log(err);
      }

      // Optionally, you can handle the response or update the UI accordingly
      console.log(data);
    } catch (error) {
      // Handle errors
      console.error("Error calling sellShares function:", error);
    }
  }

  async transferETH(selfAddress, toAddress, value) {
    try {
      const transactionParameters = {
        to: toAddress, // Required except during contract publications.
        from: selfAddress, // must match user's active address.
        value: value, // Only required to send ether to the recipient from the initiating external account.
      };

      // Now we will sign the transaction
      const txHash = await this.sendTransaction(transactionParameters);
      console.log("Transaction Hash is ", txHash);
      return txHash;
    } catch (error) {
      console.error("Error transferring ETH:", error);
    }
  }

  async updateFR(selfAddress, frValue) {
    try {
      const web3 = new Web3(RPC);
      const contractABI = ABI;
      const contract = new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);

      const data = contract.methods.setSubjectFeePercent(frValue).encodeABI();

      const unsignedTx = {
        to: CONTRACT_ADDRESS,
        data: data,
        gas: GAS_LIMIT,
        from: selfAddress,
        gasPrice: GAS_PRICE,
      };
      try {
        const response = await this.sendTransaction(unsignedTx);
        console.log(response);
        return response;
      } catch (err) {
        console.log("error happened while updatingFR txn");
        console.log(err);
      }

      // Optionally, you can handle the response or update the UI accordingly
      console.log(data);
    } catch (error) {
      // Handle errors
      console.error("Error calling updatingFR function:", error);
    }
  }
}

export default FrenHiveContract;
