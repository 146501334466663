import React, { useState, useEffect, useContext } from "react";
import LeftSideBar from "../../../components/LeftSidebar/LeftSidebar";
import { usePrivy } from "@privy-io/react-auth";
import RightSideBar from "../../../components/RightSidebar/RightSidebar";
import FeedBox from "../../../components/FeedBox/FeedBox";

const MainApp = ({}) => {
  const { ready } = usePrivy();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className='bg-[#08080B] min-h-screen'>
      {!ready && (
        <div className='flex justify-center items-center h-screen  bg flex-col space-y-2'>
          <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
        </div>
      )}
      {ready && (
        <div className='flex  sm:w-full lg:w-full xl:w-4/5  mx-auto sm:px-10 min-h-screen'>
          <div className={!isMobile && "flex-none xl:w-1/4 sm:w-1/4 lg:w-1/4"}>
            <LeftSideBar isMobile={isMobile} currentActiveTab='home' />
          </div>
          <div className='flex-grow'>
            <FeedBox  isMobile={isMobile}/>
          </div>
          {!isMobile && (
            <div className='flex-none xl:w-1/4  sm:w-1/4 lg:w-1/3  '>
              {" "}
              <RightSideBar />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MainApp;
