import React, { useState } from "react";

import TradeModal from "../../Modals/TradeModal";
export default function KeyPriceTag({ currentUser, ethPrice }) {
  const usdPrice = Math.round(currentUser.price * ethPrice * 100) / 100;

  const [showModal, setShowModal] = useState(false);
  const handleBuyClick = () => {
    console.log("test..");
    setShowModal(true);
  };
  return (
    <button
      className='bg-[#1b1b24] hover:bg-[#13131a] text-gray-400 px-3 py-1 rounded-full font-semibold text-xs'
      onClick={() => {
        handleBuyClick();
      }}>
      ${usdPrice} <span className='text-green-600'>Buy</span>
      <TradeModal
        showModal={showModal}
        setShowModal={setShowModal}
        userObject={currentUser}
      />
    </button>
  );
}
