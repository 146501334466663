import React from "react";
import { timeSince } from "../../../utils/function";
import { Link } from "react-router-dom";
import defaultPfp from "../../../assets/default-profile-pic.png";
export default function TradesBox({ trade, userList, ethPrice }) {
  return (
    <div>
      <div className='flex items-center mt-4 space-y-2 '>
        <div className='flex items-center justify-start'>
          <img
            src={userList[trade.trader].pfp}

            className='h-8 w-8 rounded-full'
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultPfp;
            }}
          />
          <img
            src={userList[trade.subject].pfp}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultPfp;
            }}
            className='h-8 w-8 rounded-full'
          />
        </div>

        <div className='flex flex-col  ml-4'>
          <div className='flex '>
            <Link
              className='text-gray-300 text-sm font-semibold sm:hover:underline'
              to={`/u/${userList[trade.trader].username}`}>
              {userList[trade.trader].username}
            </Link>
            <p className='text-gray-400 text-sm font-thin ml-1'>
              {" "}
              {trade.isBuy ? "bought" : "sold"}{" "}
            </p>
            <p className='text-gray-400 text-sm font-thin ml-1'>
              {" "}
              {trade.shareAmount}
              {"  "}
            </p>
            <Link
              className='text-gray-300 text-sm font-semibold sm:hover:underline ml-1'
              to={`/u/${userList[trade.subject].username}`}>
              {userList[trade.subject].username}
            </Link>
            <p className='text-gray-400 text-sm font-thin ml-1'> key </p>
          </div>

          <div className='flex mt-1 '>
            <p
              className={`${
                trade.isBuy ? "text-green-500" : "text-red-400"
              } text-xs `}>
              ${Math.round(trade.ethAmount * ethPrice * 100) / 100}
            </p>
            <p className={`text-xs text-gray-300 ml-2 `}>
              {timeSince(trade.blockTimestamp * 1000)} ago
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
