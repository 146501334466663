import React from "react";
import MessageComponent from "./MessageComponent";

export default function MessageBox({ isMobile }) {
  // we yet to have fetch message from api
  const messageList = [
    {
      lastMessage: "Working on fixing messages",
      profile: {
        Address: "0x81db0a49980990ffdf1f6bc031cb0fc7e69794cf",
        TwitterID: "1160604591810994176",
        PrivyDID: "did:privy:clshbzj2k00dnv35fm0kzv3qo",
        xUsername: "ItsAditya_xyz",
        xDisplayName: "Aditya Chaudhary",
        pfpUrl:
          "https://pbs.twimg.com/profile_images/1706308166298832896/2x4g4GDT_normal.jpg",
        description:
          "20. Belongs to tortured devs department. Making FrenHive.\n\nPro Crypto. Views are your moms.",
        earnedFees: 0.00029319446068,
        feePercent: "2%",
        price: 0.001615372572,
        supply: 4.39,
        portfolioValue: 0.00015408609216,
        holdersCount: 5,
        holdsCount: 3,
        refFeeEarned: 0.00001453359908,
      },
      Timestamp: 1708072108446,
    },
    {
      lastMessage: "Can you see this message?",
      profile: {
        Address: "0xcbd2b94dfbfe5a694d7c0cae0cf0487a45fcea4e",
        TwitterID: "1341538940822704129",
        PrivyDID: "did:privy:clshgx9b401vr10rumi6iv9xx",
        xUsername: "0xKuririn",
        xDisplayName: "Kuririn",
        pfpUrl:
          "https://pbs.twimg.com/profile_images/1664668608683728897/MUZKiamO_normal.jpg",
        description: null,
        earnedFees: 0.000226883082927306,
        feePercent: "5%",
        price: 0.0001617,
        supply: 1,
        portfolioValue: 0,
        holdersCount: 1,
        holdsCount: 1,
        refFeeEarned: 0,
      },
      Timestamp: 1707992965018,
    },
  ];

  return (
    <div>
      <div className={`border-x border-[#222222] min-h-screen w-full`}>
        <div className={`flex shadow-md `}>
          {" "}
          <div className='w-full flex   border-[#222222] border-y  bg-[#08080B]  l-0 r-0 -z-1'>
            <div
              className={`text-gray-100 font-bold px-6 py-4  flex justify-center items-center space-x-3  text-xl`}>
              Messages
            </div>
          </div>
        </div>
        <div className="w-full bg-[#1B1B24] text-gray-100 px-6 py-2">Rooms are offline. We are working to bring it back.</div>
        <div className='w-full flex flex-col  '>
          {messageList.map((message, index) => {
            return <MessageComponent message={message} />;
          })}
        </div>
      </div>
    </div>
  );
}
