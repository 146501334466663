import React, { useState, useContext } from "react";
import {
  MdHome,
  MdOutlineWallet,
  MdOutlineNotifications,
  MdOutlineMessage,
} from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineSettings } from "react-icons/md";
import LogoSVG from "../../assets/logo.svg";
import SearchModal from "../Modals/SearchModal";
import PrivyContext from "../../AppStates/PrivyContext";

const LeftSideBar = ({ isMobile, currentActiveTab = "home" }) => {
  const privyContextObject = useContext(PrivyContext);
  const navigate = useNavigate();
  function handleButtonClick(tab) {
    //navigate to tab
    if (tab === "profile") {
      if (!privyContextObject.hasLoggedIn) {
        navigate(`/u/itsaditya_xyz`);
        return;
      } else {
        navigate(`/u/${privyContextObject.userData.xUsername}`);
        return;
      }
    }
    navigate(`/${tab}`);
  }

  const [showSearchModal, setShowSearchModal] = useState(false);

  return (
    <div className=''>
      {isMobile && (
        <div className='fixed bottom-0 left-0 right-0 py-3 px-5   flex justify-between  bg-[#08080B] border-t border-[#222222]'>
          <button
            className={`${
              currentActiveTab === "home" ? "text-white" : "text-gray-400"
            }  rounded-3xl flex justify-center items-center space-x-3 `}
            onClick={() => {
              handleButtonClick("home");
            }}>
            <MdHome size={30} />
          </button>

          <button
            className={`${
              currentActiveTab === "messages" ? "text-white" : "text-gray-400"
            }  rounded-3xl flex justify-center items-center space-x-3 `}
            onClick={()=>{handleButtonClick('messages')}}
            >
            <MdOutlineMessage size={28} />
          </button>
          <button
            className={`${
              currentActiveTab === "notifications"
                ? "text-white"
                : "text-gray-400"
            }  rounded-3xl flex justify-center items-center space-x-3 `}
            onClick={() => {
              handleButtonClick("notifications");
            }}>
            <MdOutlineNotifications size={30} />
          </button>

          <button
            className={`${
              currentActiveTab === "wallet" ? "text-white" : "text-gray-400"
            }  rounded-3xl flex justify-center items-center space-x-3 `}
            onClick={() => {
              handleButtonClick("wallet");
            }}>
            <MdOutlineWallet size={30} />
          </button>
        </div>
      )}
      <SearchModal
        showModal={showSearchModal}
        setShowModal={setShowSearchModal}
      />
      {!isMobile && (
        <div>
          <div className='border-b border-[#222222] w-full '>
            <div>
              <Link
                className='flex items-center fixed xl:pl-24 md:pl-6  py-4 ml-2 '
                to='/home'>
                <img
                  src={LogoSVG}
                  alt='logo'
                  className='h-8 w-8 text-gray-300'
                />
                <p className='text-gray-300 text-base font-bold ml-2'>
                  FrenHive
                </p>
              </Link>
            </div>
          </div>
          <div className='flex flex-col xl:pl-20 md:pl-2 py-20 justify-start items-start fixed'>
            <button
              className={` ${
                currentActiveTab === "home" ? "text-white" : "text-gray-400"
              } px-6 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                handleButtonClick("home");
              }}>
              <MdHome size={28} />
              <p className='text-xl font-extrabold'>Home</p>
            </button>

            <button
              className={` ${
                currentActiveTab === "wallet" ? "text-white" : "text-gray-400"
              } px-6 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                handleButtonClick("wallet");
              }}>
              <MdOutlineWallet size={28} />
              <p className='text-xl font-extrabold'>Wallet</p>
            </button>

            <button
              className={` ${
                showSearchModal === true ? "text-white" : "text-gray-400"
              } px-6 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                setShowSearchModal(true);
              }}>
              <FaSearch size={24} />
              <p className='text-xl font-extrabold'>Search</p>
            </button>

            <button
              className={` ${
                currentActiveTab === "notifications"
                  ? "text-white"
                  : "text-gray-400"
              } px-5 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                handleButtonClick("notifications");
              }}>
              <MdOutlineNotifications size={28} />
              <p className='text-xl font-extrabold'>Notifications</p>
            </button>

            <button
              className={` ${
                currentActiveTab === "messages" ? "text-white" : "text-gray-400"
              } px-6 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={()=>{handleButtonClick('messages')}}
              >
              <MdOutlineMessage size={26} />
              <p className='text-xl font-extrabold'>Messages</p>
            </button>

            <button
              className={` ${
                currentActiveTab === "profile" ? "text-white" : "text-gray-400"
              } px-6 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                handleButtonClick("profile");
              }}>
              <FaRegUser size={22} />
              <p className='text-xl font-extrabold'>My Profile</p>
            </button>

            <button
              className={` ${
                currentActiveTab === "settings" ? "text-white" : "text-gray-400"
              } px-5 py-2.5 mb-4 rounded-3xl flex justify-center items-center space-x-3 hover:bg-[#1b1b24]`}
              onClick={() => {
                handleButtonClick("settings");
              }}>
              <MdOutlineSettings size={28} />
              <p className='text-xl font-extrabold'>Settings</p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeftSideBar;
