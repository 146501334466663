import PrivyContext from "./PrivyContext";
import { useState } from "react";

const PrivyState = (props) => {
  const [userData, setUserData] = useState({});
  const [hasLoggedIn, setHasLoggedIn] = useState(false);

  const [referralData, setReferralData] = useState([]);
  const [ethBalance, setEthBalance] = useState(0);
  const [ethPrice, setEthPrice] = useState(2500);

  const [hiveFeed, setHiveFeed] = useState([]);
  const [newFeed, setNewFeed] = useState([]);

  const [trades, setTrades] = useState([]);

  const [keysHeld, setKeysHeld] = useState([]);

  const [referralEarnings, setReferralEarnings] = useState([]);

  const [trendingUsers, setTrendingUsers] = useState([]);

  const [notifications, setNotifications] = useState([]);

  const [newUsers, setNewUsers] = useState([]);

  const [postTab, setPostTab] = useState("hot");

  const [points, setPoints] = useState(0);

  const [pointsLeaderboards, setPointsLeaderboards] = useState([]);

  const updatePointsLeaderboards = (newData) => {
    console.log("points leaderboards updated");
    setPointsLeaderboards(newData);
  };

  
  const updatePoints = (newPoints) => {
    console.log("points updated");
    setPoints(newPoints);
  };
  const updatePostTab = (newTab) => {
    console.log("post tab updated");
    setPostTab(newTab);
  };
  const updateNewFeed = (newData) => {
    console.log("new feed updated");
    setNewFeed(newData);
  };
  const updateNewUsers = (newData) => {
    console.log("new users updated");
    setNewUsers(newData);
  };

  const updateNotifications = (newNotifications) => {
    console.log("notifications updated");
    setNotifications(newNotifications);
  };

  const updateTrendingUsers = (newData) => {
    console.log("updated trending users");
    setTrendingUsers(newData);
  };

  const updateReferralEarnings = (newData) => {
    console.log("updated referral earnings");
    setReferralEarnings(newData);
  };

  const updateKeysHeld = (newData) => {
    console.log("updated keys held");
    setKeysHeld(newData);
  };

  const updateUserData = (userData) => {
    console.log("update user data happened!!");
    setUserData(userData);
  };
  const updateHasLoggedIn = (hasLoggedIn) => {
    setHasLoggedIn(hasLoggedIn);
  };
  const updateEthBalance = (newBalance) => {
    console.log("eth balance updated");
    setEthBalance(newBalance);
  };

  const updateReferralData = (newReferralData) => {
    console.log("referral data updated");
    setReferralData(newReferralData);
  };

  const updateEthPrice = (newEthPrice) => {
    console.log("eth price updated");
    setEthPrice(newEthPrice);
  };

  const updateHiveFeed = (newHiveFeed) => {
    console.log("hive feed updated");
    setHiveFeed(newHiveFeed);
  };

  const updateTrades = (newTrades) => {
    console.log("trades updated");
    setTrades(newTrades);
  };

  return (
    <PrivyContext.Provider
      value={{
        userData,
        hasLoggedIn,
        updateUserData,
        updateHasLoggedIn,
        referralData,
        updateReferralData,
        ethBalance,
        updateEthBalance,
        ethPrice,
        updateEthPrice,
        hiveFeed,
        updateHiveFeed,
        trades,
        updateTrades,
        keysHeld,
        updateKeysHeld,
        referralEarnings,
        updateReferralEarnings,
        trendingUsers,
        updateTrendingUsers,
        notifications,
        updateNotifications,
        newUsers,
        updateNewUsers,
        newFeed,
        updateNewFeed,
        postTab, 
        updatePostTab,
        points,
        updatePoints,

        pointsLeaderboards,
        updatePointsLeaderboards,
      }}>
      {props.children}
    </PrivyContext.Provider>
  );
};

export default PrivyState;
