import React, { useState, useEffect, useContext } from "react";
import LeftSideBar from "../../components/LeftSidebar/LeftSidebar";

import RightSideBar from "../../components/RightSidebar/RightSidebar";
import toast from "react-hot-toast";
import API from "../../API/API";
import { usePrivy } from "@privy-io/react-auth";
import { useParams } from "react-router-dom";
import PrivyContext from "../../AppStates/PrivyContext";
import CreateCommentModal from "../../components/Modals/CreateCommentModal";
import defaultPfp from "../../assets/default-profile-pic.png";
import { timeSince } from "../../utils/function";
import { Link } from "react-router-dom";
import KeyPriceTag from "../../components/FeedBox/subComponents/KeyPriceTag";
import { FaRegCommentAlt } from "react-icons/fa";
import { MdOutlineIosShare } from "react-icons/md";
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { IoMdArrowRoundBack } from "react-icons/io";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import CommentBox from "./subComponent.jsx/CommentBox";
const Posts = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [navTab, setNavTab] = useState("Posts");
  const [userPost, setUserPost] = useState(null);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [upvoteCount, setUpvoteCount] = useState(0);

  const { postID } = useParams();
  const { getAccessToken } = usePrivy();

  const [commentList, setCommentList] = useState(true);
  const privyContextObject = useContext(PrivyContext);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  async function handleUpvote() {
    if (!privyContextObject.hasLoggedIn) {
      return alert("you need to be loggged in.");
    } else {
      setUpvoteCount(upvoteCount + 1);
      const accessToken = await getAccessToken();
      const ApiObject = new API(accessToken);
      const likeResponse = await ApiObject.likePost(postID);
      console.log(likeResponse);
    }
  }
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function handleLinkCopy(postID) {
    navigator.clipboard.writeText(`${window.location.origin}/posts/${postID}`);
    toast.success("Link Copied!", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  }

  async function getPost() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const result = await ApiObject.getSinglePost(postID);

    const comment = await ApiObject.getCommentsUnderPost(postID);
    setCommentList(comment);
    console.log(result);
    setUserPost(result);
    setUpvoteCount(result.LikesCount);
    setLoadingPosts(false);
  }

  useEffect(() => {
    console.log(postID);
    if (privyContextObject.hasLoggedIn && postID) {
      if (userPost === null) {
        getPost();
      }
    }
  }, [postID, privyContextObject.hasLoggedIn]);

  function handleBackClick() {
    // go back in history using useNavigate
    navigate(-1);
  }

  return (
    <div className='bg-[#08080B] min-h-screen '>
      <div className='sm:hidden border-b border-[#222222]'>
        <Header />
      </div>

      <div className='flex  sm:w-full lg:w-full xl:w-4/5  mx-auto sm:px-10 min-h-screen  '>
        <div
          className={
            !isMobile && "flex-none w-1/4 xl:w-1/4 sm:w-1/4 lg:w-1/4 "
          }>
          <LeftSideBar isMobile={isMobile} currentActiveTab='' />
        </div>
        <div className='flex-grow border-[#222222] sm:border-x '>
          {loadingPosts && (
            <div className='flex justify-center items-center mt-4 bg flex-col space-y-2 min-h-screen'>
              <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
            </div>
          )}

          {!loadingPosts && (
            <>
              <div className='bg-[#08080B] text-gray-200 text-base font-semibold flex border-b border-[#222222] py-3 pl-3  '>
                <button
                  onClick={() => {
                    handleBackClick();
                  }}>
                  <IoMdArrowRoundBack size={20} />
                </button>

                <span className='ml-3 sm:text-xl'>
                  {userPost.xUsername}'s Post
                </span>
              </div>
              <div className='bg-[#08080B] py-2 border-b border-[#222222] px-3 hover:cursor-pointer hover:bg-[#101016] '>
                <CreateCommentModal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  parentPost={userPost}
                />
                <div className='sm:flex  space-x-2 hidden sm:visible'>
                  <Link to={`/u/${userPost.xUsername}`} className='flex'>
                    <img
                      src={userPost.pfpUrl}
                      className='h-11 w-11 rounded-full'
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultPfp;
                      }}
                    />
                  </Link>
                  <Link
                    className='flex space-x-1 mt-2'
                    to={`/u/${userPost.xUsername}`}>
                    <p className='text-gray-100 text-base font-semibold hover:underline'>
                      {userPost.xDisplayName}
                    </p>
                    <p className='text-gray-400 text-base'>
                      {userPost.xUsername}
                    </p>
                  </Link>

                  <div className='mt-2'>
                    <KeyPriceTag
                      currentUser={userPost}
                      ethPrice={privyContextObject.ethPrice}
                    />
                  </div>

                  <p className='text-gray-400 text-xs ml-auto mt-3'>
                    {timeSince(userPost.Timestamp)}
                  </p>
                </div>

                <div className='flex  space-x-2 sm:hidden'>
                  <Link to={`/u/${userPost.xUsername}`} className='flex'>
                    <img
                      src={userPost.pfpUrl}
                      className='h-11 w-11 rounded-full'
                    />
                  </Link>
                  <div className='flex flex-col ml-1'>
                    <Link
                      className='flex flex-col ml-1'
                      o={`/u/${userPost.xUsername}`}>
                      <p className='text-gray-100 text-base font-semibold'>
                        {userPost.xDisplayName}
                      </p>
                      <p className='text-gray-400 text-base'>
                        {userPost.xUsername}
                      </p>
                    </Link>
                  </div>

                  <div className=''>
                    <KeyPriceTag
                      currentUser={userPost}
                      ethPrice={privyContextObject.ethPrice}
                    />
                  </div>

                  <p className='text-gray-400 text-xs ml-auto mt-1'>
                    {timeSince(userPost.Timestamp)}
                  </p>
                </div>

                <p className='text-gray-100 ml-14 text-start text-clip mt-2 sm:mt-0'>
                  {userPost.Content.split("\n").map((line, index) => (
                    <span key={index}>
                      {index > 0 && <br />}{" "}
                      {/* Add line break after the first line */}
                      {line.split(" ").map((word, wordIndex) => {
                        // Regular expression to match URLs
                        const urlPattern = /(https?:\/\/[^\s]+)/g;

                        if (word.startsWith("@")) {
                          return (
                            <React.Fragment key={wordIndex}>
                              <Link
                                to={`/u/${word.slice(1)}`}
                                className='text-[#EAB308]'>
                                {word}
                              </Link>{" "}
                            </React.Fragment>
                          );
                        } else if (urlPattern.test(word)) {
                          return (
                            <React.Fragment key={wordIndex}>
                              <a
                                href={word}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='text-[#EAB308]'>
                                {word}
                              </a>{" "}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment key={wordIndex}>
                              <span>{word}</span>{" "}
                            </React.Fragment>
                          );
                        }
                      })}
                    </span>
                  ))}
                </p>
                <div className='flex space-x-1 mt-4 mb-2 ml-14'>
                  <button
                    className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20  justify-center'
                    onClick={() => {
                      handleUpvote();
                    }}>
                    <BiUpvote size={15} className='text-gray-400' />
                    <p className='text-gray-200'>{upvoteCount}</p>
                  </button>

                  <button
                    className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20 justify-center items-center'
                    onClick={() => {
                      setShowModal(true);
                    }}>
                    <FaRegCommentAlt size={12} className='text-gray-400' />
                    <p className='text-gray-200'>{userPost.CommentsCount}</p>
                  </button>

                  <button
                    className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl justify-center items-center min-w-20 '
                    onClick={() => {
                      handleLinkCopy(postID);
                    }}>
                    <MdOutlineIosShare size={14} className='text-gray-400' />
                    <p className='text-gray-200'>Share</p>
                  </button>
                </div>
              </div>
            </>
          )}
          {!loadingPosts &&
            commentList.map((commentObject) => {
              return (
                <CommentBox parentPost={userPost} postObject={commentObject} />
              );
            })}
        </div>

        {!isMobile && (
          <div className='flex-none xl:w-1/4 sm:w-1/4 lg:w-1/3 '>
            {" "}
            <RightSideBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default Posts;
