import React from "react";

export default function HolderList({ user, ethPrice }) {
  return (
    <tr className='  border-b border-[#222222] pb-2 '>
      <td className='py-3 px-6 '>
        <div className='flex items-center space-x-2 '>
          <img src={user.pfp} alt='pfp' className='w-8 h-8 rounded-full' />
          <div className=''>
            <p className='text-gray-300 text-base font-semibold'>{user.displayName}</p>
            <p className='text-gray-400 text-sm font-thin'>@{user.username}</p>
          </div>
        </div>
      </td>
      <td className='py-3 px-6 text-end'>
        <p className='text-gray-400 text-sm font-thin'>{Math.round(user.shares*10)/10}</p>
      </td>
      <td className='py-3 px-6 text-end'>
        <p className='text-gray-400 text-sm font-thin'>${(Math.round(user.portfolioValue * ethPrice*100)/100).toLocaleString()}</p>
      </td>
    </tr>
  );
}
