import React, { useEffect, useState } from "react";
import { CiExport } from "react-icons/ci";
import { FaParachuteBox } from "react-icons/fa";
import KeyComponent from "./KeyComponent";
import PrivyContext from "../../../AppStates/PrivyContext";
import { useContext } from "react";
import API from "../../../API/API";
import { Link } from "react-router-dom";
export default function PointsSection({ isMobile }) {
  const key = [
    {
      pfpUrl:
        "https://images.bitclout.com/7561dce8a78e266e33ce4b3340f8b60ce57e2a2dcb17aa22fe2fc0eac5c14432.webp",
      username: "itsaditya_xyz",
      name: "Aditya Chaudhary",
      keyPrice: 1.2,
      keyPriceUSD: 1236,
      holding: 1,
      points: 2450,
    },
    {
      name: "Clayoglesby",
      username: "Clayoglesby",

      pfpUrl:
        "https://pbs.twimg.com/profile_images/1625585980135596058/KNTUUFjr_400x400.jpg",
      keyPriceUSD: 2450,
      keyPrice: 1.4,
      points: 2670,
    },
  ];
  const privyContextObject = useContext(PrivyContext);
  const [loadingPointLeaderboard, setLoadingPointLeaderboard] = useState(
    privyContextObject.pointsLeaderboards.length === 0
  );
  async function getPoints() {
    try {
      const ApiObject = new API();
      const result = await ApiObject.getPoints(
        privyContextObject.userData.xUsername
      );
      privyContextObject.updatePoints(result.totalPoints);

      const result2 = await ApiObject.getPointsLeaderboards(50);
      privyContextObject.updatePointsLeaderboards(result2);
      setLoadingPointLeaderboard(false);
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    if (privyContextObject.hasLoggedIn) {
      getPoints();
    }
  }, [privyContextObject.hasLoggedIn]);

  return (
    <div className='w-full '>
      <div className='bg-gradient-to-b from-[#1B1B24] to-[#08080B] mx-4 my-4 rounded-lg'>
        <div className='flex flex-col items-center'>
          <div className='flex flex-col items-center'>
            <p className='text-base  text-gray-300 mt-10 px-4 text-center'>
              Every week you are airdropped FrenHive points. Points will have
              future use case for FrenHive.
            </p>
            <p className='text-base  text-gray-300 mt-10 px-4 text-center'>
              Your Airdrop Points
            </p>
            <div className='flex mt-2 items-center'>
              <p className='text-2xl font-bold text-gray-200 mt-2'>
                {privyContextObject.points}
              </p>
              <span className='text-gray-400 mt-1 ml-1'>
                <FaParachuteBox size={30} />
              </span>
            </div>
          </div>
        </div>

        {loadingPointLeaderboard && (
          <div className='flex justify-center items-center mt-4 bg flex-col space-y-2 min-h-screen'>
            <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
          </div>
        )}
       
      </div>
      <p className='text-gray-200 text-lg mt-16 sm:pb-4 mx-5'>Leaderboards</p>
      
        {!loadingPointLeaderboard && (
          <div className='flex justify-center sm:mx-4'>
            <table className='w-full max-w-screen-lg  shadow-md rounded sm:my-6'>
              <thead>
                <tr className='text-gray-200 text-lg mt-16 pb-4 '>
                  <th className='py-3 px-6 text-left text-gray-200 t text-sm font-thin'>
                    User
                  </th>

                  <th className='py-3 px-6  text-gray-200 t text-sm font-thin text-end'>
                    Points
                  </th>
                </tr>
              </thead>
              <tbody className='text-gray-600 text-sm font-light'>
                {privyContextObject.pointsLeaderboards.map((user) => {
                  return (
                    <tr className=' w-full border-b border-[#222222] pb-2 '>
                      <td className='py-3 px-6 '>
                        <Link
                          className='flex items-center space-x-2 '
                          to={`/u/${user.username}`}>
                          <img
                            src={user.pfp}
                            alt='pfp'
                            className='w-8 h-8 rounded-full'
                          />
                          <div className=''>
                            <p className='text-gray-300 text-base font-semibold'>
                              {user.displayName}
                            </p>
                            <p className='text-gray-400 text-sm font-thin'>
                              @{user.username}
                            </p>
                          </div>
                        </Link>
                      </td>

                      <td className='py-3 px-6 text-end'>
                        <p className='text-gray-400 text-sm font-thin'>
                          {user.points}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
    </div>
  );
}
