import React from "react";
import { Link } from "react-router-dom";
import { timeSince } from "../../../utils/function";
import defaultPfp from "../../../assets/default-profile-pic.png"
export default function NewFrens({ user }) {
  return (
    <div className='bg-[#08080B] py-2 border-b border-[#222222]  hover:cursor-pointer hover:bg-[#101016]'>
      <Link className='flex  space-x-2' to={`/u/${user.username}`}>
        <img src={user.pfp}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = defaultPfp;
          }}
        className='h-9 w-9 rounded-full' />
        <div className='flex space-x-1 mt-2'>
          <p className='text-gray-200 text-base font-semibold sm:hover:underline'>
            {user.displayName}
          </p>
          <p className='text-gray-400 text-base'>@{user.username}</p>
        </div>
        <div className='mt-2'>{/* <KeyPriceTag usdPrice={user} /> */}</div>
        <p className='text-gray-400 text-xs ml-auto mt-3'>{timeSince(user.timestamp)}</p>
      </Link>
    </div>
  );
}
