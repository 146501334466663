function timeSince(timestamp) {
  const now = new Date();

  //console.log(now)

  const past = new Date(timestamp);
  const diffInMilliseconds = now - past;

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays > 0) {
    return `${diffInDays}d`;
  } else if (diffInHours > 0) {
    return `${diffInHours}h`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes}m`;
  } else {
    return `${diffInSeconds}s`;
  }
}

function getSellingPriceAfterFee(supply, sharesToSell, subjectFeePercent=5) {
  const protocolFeePercent = 2 * 10 ** 16; // 2% default
  const defaultSubjectFeePercent = subjectFeePercent * 10 ** 16; // 5% default
  const MAX_SUBJECT_FEE = 5 * 10 ** 16;
  const ONE_SHARE = 10 ** 6; // equals 1 share

  function getPrice(supply, amount) {
    // Calculate area under the curve from 0 to x + n
    const areaXPlusN =
      supply === 0 && amount === ONE_SHARE
        ? 0
        : 66 * Math.pow(supply + amount, 3);

    // Calculate area under the curve from 0 to x
    const areaX =
      supply === 0 && amount === ONE_SHARE ? 0 : 66 * Math.pow(supply, 3);

    // Return the difference, scaled down
    return (areaXPlusN - areaX) / (3 * ONE_SHARE);
  }

  function getSellPrice(supply, amount) {
    return getPrice(supply - amount, amount);
  }

  const price = getSellPrice(supply, sharesToSell);
  const protocolFee = (price * protocolFeePercent) / 1e18;
  const subjectFee = (price * defaultSubjectFeePercent) / 1e18;
  return price - protocolFee - subjectFee;
}
export { timeSince, getSellingPriceAfterFee };
