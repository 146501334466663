import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef, useState, useContext } from "react";
import { MdOutlineClose } from "react-icons/md";
import API from "../../API/API";
import toast from "react-hot-toast";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../AppStates/PrivyContext";
import defaultPfp from "../../assets/default-profile-pic.png";
import { timeSince } from "../../utils/function";
import { Link } from "react-router-dom";
function CreateCommentModal({ showModal, setShowModal, parentPost }) {
  const privyContextObject = useContext(PrivyContext);
  const [creatingPost, setCreatingPost] = useState(false);
  const [postBody, setPostBody] = useState("");
  const textareaRef = useRef(null);
  const { getAccessToken } = usePrivy();
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to auto to recalculate
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the new height
    }
  };

  const handleInputChange = (e) => {
    setPostBody(e.target.value);
    adjustTextareaHeight();
  };

  const createPost = async () => {
    if (creatingPost) {
      return;
    }

    if (privyContextObject.hasLoggedIn) {
      if (postBody.length === 0) {
        toast.error("Comment cannot be empty", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
        return;
      }
      setCreatingPost(true);
      const loadingToast = toast.loading("Replying", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
      const authToken = await getAccessToken();
      const ApiObject = new API(authToken);

      const result = await ApiObject.createPost(
        postBody,
        null,
        parentPost.PostID
      );
      console.log(result);

      // close the loading toast
      toast.dismiss(loadingToast);

      if (result.success) {
        toast.success("Replied!", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
        setPostBody("");
      } else {
        toast.error("Error creating comment", {
          style: {
            backgroundColor: "#1b1b24",
            color: "white",
          },
        });
      }

      setCreatingPost(false);
      setShowModal(false);
    } else {
      toast.error("You are not logged in.", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
    }
  };

  return (
    <div className=''>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-70' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl border border-[#040405] text-left align-middle shadow-xl transition-all bg-[#1b1b24]'>
                  <div className='flex justify-end space-x-4 py-3 px-6 fixed -right-6 -top-4 z-50 '>
                    <button
                      type='button'
                      className='inline-flex justify-center rounded-l-lg border-none px-2 py-2 text-sm font-medium  focus:outline-none bg-slate-800 hover:bg-slate-900 text-white'
                      onClick={() => setShowModal(false)}>
                      <MdOutlineClose size={24} />
                    </button>
                  </div>
                  <Dialog.Title
                    as='h3'
                    className='text-base leading-6 font-xl text-center my-4 font-semibold text-gray-100'>
                    Replying to{" "}
                    <span className='text-blue-100'>
                      {" "}
                      @{parentPost.xUsername}{" "}
                    </span>
                  </Dialog.Title>
                  <div className=' px-2'>
                    <div className='border-b border-[#040405] pb-4'>
                      <div className=' pb-3'>
                        <div className='sm:flex  space-x-2 hidden sm:visible'>
                          <div className='flex'>
                            <img
                              src={parentPost.pfpUrl}
                              className='h-11 w-11 rounded-full'
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = defaultPfp;
                              }}
                            />
                          </div>
                          <div className='flex space-x-1 mt-2'>
                            <p className='text-gray-100 text-base font-semibold hover:underline'>
                              {parentPost.xDisplayName}
                            </p>
                            <p className='text-gray-400 text-base'>
                              {parentPost.xUsername}
                            </p>
                          </div>

                          <p className='text-gray-400 text-xs ml-auto mt-3'>
                            {timeSince(parentPost.Timestamp)}
                          </p>
                        </div>
                      </div>

                      <div className='flex  space-x-2 sm:hidden'>
                        <div className='flex'>
                          <img
                            src={parentPost.pfpUrl}
                            className='h-11 w-11 rounded-full'
                          />
                        </div>
                        <div className='flex flex-col ml-1'>
                          <div
                            className='flex flex-col ml-1'
                            o={`/u/${parentPost.xUsername}`}>
                            <p className='text-gray-100 text-base font-semibold'>
                              {parentPost.xDisplayName}
                            </p>
                            <p className='text-gray-400 text-base'>
                              {parentPost.xUsername}
                            </p>
                          </div>
                        </div>

                        <p className='text-gray-400 text-xs ml-auto mt-1'>
                          {timeSince(parentPost.Timestamp)}
                        </p>
                      </div>
                      <p className='text-gray-100 ml-14 text-start text-clip mt-2 sm:mt-0'>
                        {parentPost.Content.split("\n").map((line, index) => (
                          <span key={index}>
                            {index > 0 && <br />}{" "}
                            {/* Add line break after the first line */}
                            <span className=''>{line}</span>
                          </span>
                        ))}
                      </p>
                    </div>

                    <div className='bg-[#1b1b24] pt-2  border-b-2 border-[#222222]'>
                      <div className='flex'>
                        <img
                          src={
                            Object.keys(privyContextObject.userData).length ===
                            0
                              ? defaultPfp
                              : privyContextObject.userData.pfpUrl
                          }
                          className='h-12 w-12 rounded-full mt-3'
                        />
                        <textarea
                          ref={textareaRef}
                          className='w-full  bg-[#1b1b24] outline-none   text-white rounded-xl p-4 resize-none min-h-44 max-h-60 '
                          style={{
                            scrollbarWidth: "thin",
                            scrollbarColor: "#4B5563 #1F2937",
                          }}
                          placeholder='Add Comment'
                          value={postBody}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='flex justify-end pb-2'>
                        <button
                          className='bg-yellow-500 hover:bg-yellow-600 text-black rounded-xl px-5 py-2 mt-2'
                          onClick={createPost}>
                          Reply
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default CreateCommentModal;
