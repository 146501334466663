import React from "react";
import { BiUpvote } from "react-icons/bi";
import { FaRegCommentAlt } from "react-icons/fa";
import { MdOutlineIosShare } from "react-icons/md";
import { MdModeComment } from "react-icons/md";
export default function CommentBox({ commentObject }) {
  return (
    <div className='bg-[#08080B]   px-3 hover:cursor-pointer sm:hover:bg-[#101016] w-full py-3'>
      <div className='flex justify-between items-center'>
        <div className='flex  space-x-2'>
          <div className='flex items-center space-x-3'>
            <img
              src={commentObject.actionerProfile.pfpURL}
              alt=''
              className='w-10 h-10 rounded-full'
            />
            <p>
              <span className='font-bold'>
                {commentObject.actionerProfile.name}
              </span>{" "}
              <span className='font-thin  text-gray-200'>replied to </span>{" "}
              <span className='text-gray-400 font-thin italic '>
                {commentObject.extraData.postContent}
              </span>
            </p>
          </div>
        </div>
        <div>
          <MdModeComment size={20} className='text-gray-200' />
        </div>
      </div>

      <p className='text-gray-100 ml-14 my-1 text-start text-clip'>
        {commentObject.extraData.commentContent
          .split("\n")
          .map((line, index) => (
            <span key={index}>
              {index > 0 && <br />} {/* Add line break after the first line */}
              <span className=''>{line}</span>
            </span>
          ))}
      </p>
      <div className='flex space-x-1 mt-4 mb-2 ml-12'>
        <button className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20  justify-center'>
          <BiUpvote size={15} className='text-gray-400' />
          <p className='text-gray-200'>2</p>
        </button>

        <button className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20 justify-center items-center'>
          <FaRegCommentAlt size={12} className='text-gray-400' />
          <p className='text-gray-200'>1</p>
        </button>

        <button className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl justify-center items-center min-w-20 '>
          <MdOutlineIosShare size={14} className='text-gray-400' />
          <p className='text-gray-200'>Share</p>
        </button>
      </div>
    </div>
  );
}
