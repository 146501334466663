const DEFAULT_BACKEND_URL = "https://frenhive-api-mainnet.tools.workers.dev/";

class API {
  constructor(authToken) {
    this.baseUrl = DEFAULT_BACKEND_URL;
    this.authToken = authToken;
  }

  // ALL APIS ARE HERE...
  async addUserToBackend(
    xUsername,
    xDisplayName,
    twitterId,
    address,
    privyDID,
    pfpUrl,
    refCode
  ) {
    const path = this.baseUrl + "api/user";
    const data = {
      xUsername: xUsername,
      xDisplayName: xDisplayName,
      address: address,
      privyDID: privyDID,
      pfpUrl: pfpUrl,
      refCode: refCode,
      twitterId: twitterId.toString(),
    };
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in addUserToBackend");
      console.log(error);
      return error;
    }
  }

  async getETHBalance(username) {
    const path = this.baseUrl + `api/user/${username}/balance`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getETHBalnace");
      console.log(error);
      return error;
    }
  }

  async getKeyPrice(address, shares) {
    const path = this.baseUrl + `api/get-buy-price`;
    const data = {
      address,
      shares,
    };
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in getting price");
      console.log(error);
      return error;
    }
  }
  async searchUsers(username) {
    const path = this.baseUrl + `api/user-search/${username}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in searchUsers");
      console.log(error);
      return error;
    }
  }

  async getSellingPrice(address, shares) {
    const path = this.baseUrl + `api/get-sell-price`;
    const data = {
      address,
      shares,
    };
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in getting price");
      console.log(error);
      return error;
    }
  }

  async updateUserInBackend(
    xUsername,
    xDisplayName,
    twitterId,
    address,
    privyDID,
    pfpUrl
  ) {
    const path = this.baseUrl + "api/user";
    const data = {
      xUsername: xUsername,
      xDisplayName: xDisplayName,
      address: address,
      privyDID: privyDID,
      pfpUrl: pfpUrl,
      twitterId: twitterId.toString(),
    };
    try {
      const result = await this.putData(path, data);
      return result;
    } catch (error) {
      console.log("error in updateUserInBackend");
      console.log(error);
      return error;
    }
  }

  async validateReferralCode(refCode) {
    const path = this.baseUrl + `api/ref/${refCode}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in validateReferralCode");
      console.log(error);
      return error;
    }
  }

  async checkUserExistence(privyDID) {
    const path = this.baseUrl + `api/user/privyDID/${privyDID}/exists`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in checkUserExistence");
      console.log(error);
      return error;
    }
  }

  async getUserInfo(username) {
    const path = this.baseUrl + `api/user/${username}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getUserInfo");
      console.log(error);
      return error;
    }
  }

  async createPost(content, PrivyDID, parentID = null) {
    const path = this.baseUrl + "api/post";
    const data = {
      content: content,
      PrivyDID: PrivyDID,
      parentID: parentID,
    };
    if (parentID === null) {
      delete data.parentID;
    }
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in createPost");
      console.log(error);
      return error;
    }
  }

  async getTrades(type = null, username = null, timestamp = null, limit = 50) {
    let url = this.baseUrl + `api/trades?limit=${limit}`; // to get recent trades
    console.log(type);
    console.log(username);
    if (type !== null && username !== null) {
      url = this.baseUrl + `api/trades/${type}/${username}/?limit=${limit}`;
    }
    if (timestamp !== null) {
      url = url + `&timestamp=${timestamp}`;
    }
    try {
      const result = await this.getData(url);
      return result;
    } catch (error) {
      console.log("error in getTrades");
      console.log(error);
      return error;
    }
  }

  async getPoints(username) {
    let url = this.baseUrl + `api/points/${username}`;
    try {
      const result = await this.getData(url);
      return result;
    } catch (error) {
      console.log("error in getPoints");
      console.log(error);
      return error;
    }
  }

  async getPointsLeaderboards(limit = 50) {
    let url = this.baseUrl + `api/points-leaderboard/${limit}`;
    try {
      const result = await this.getData(url);
      return result;
    } catch (error) {
      console.log("error in getPointsLeaderboards");
      console.log(error);
      return error;
    }
  }

  async updateUserDescription(description) {
    const path = this.baseUrl + "api/user/bio";
    const data = {
      description: description,
    };
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in updateUserDescription");
      console.log(error);
      return error;
    }
  }

  async getHoldersOfUser(username) {
    const path = this.baseUrl + `api/user/${username}/holders`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getHoldersOfUser");
      console.log(error);
      return error;
    }
  }
  async getTopUsers(num = 5) {
    const path = this.baseUrl + `api/top-users/${num}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getTopUsers");
      console.log(error);
      return error;
    }
  }
  async getHoldingsOfUser(username) {
    const path = this.baseUrl + `api/user/${username}/holdings`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getHoldingsOfUser");
      console.log(error);
      return error;
    }
  }

  async getUserReferredBySelf() {
    const path = this.baseUrl + `api/get-user-referrals`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getUserReferredBySelf");
      console.log(error);
      return error;
    }
  }

  async getInviteCodesOfSelf() {
    const path = this.baseUrl + `api/get-user-invites`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getInviteCodesOfSelf");
      console.log(error);
      return error;
    }
  }

  async whoReferredThisUser() {
    const path = this.baseUrl + `api/get-user-ref`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in whoReferredThisUser");
      console.log(error);
      return error;
    }
  }

  async getTrendingUsers(limit = 10) {
    const path = this.baseUrl + `api/trending/${limit}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getTrendingUsers");
      console.log(error);
      return error;
    }
  }

  async getPostsByUsername(username, limit = 50, timestamp = null) {
    let path = this.baseUrl + `api/user/${username}/posts`;
    if (timestamp !== null) {
      path = path + `?lastTimestamp=${timestamp}`;
    }
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getPostsByUsername");
      console.log(error);
      return error;
    }
  }

  async getSinglePost(postID) {
    const path = this.baseUrl + `api/post/${postID}`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getSinglePost");
      console.log(error);
      return error;
    }
  }

  async getCommentsUnderPost(postID) {
    const path = this.baseUrl + `api/post/${postID}/comments`;
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getCommentsUnderPost");
      console.log(error);
      return error;
    }
  }

  async deletePost(postID) {
    const path = this.baseUrl + `api/post`;
    const data = {
      postID: postID,
    };
    try {
      const result = await this.deleteData(path, data);
      return result;
    } catch (error) {
      console.log("error in deletePost");
      console.log(error);
      return error;
    }
  }

  async getHiveFeed(timestamp = null, limit = 50, lastScore = null) {
    let path =
      this.baseUrl +
      `api/hive-feed?lastTimestamp =${timestamp}&lastScore=${lastScore}&limit=${limit}`;
    if (timestamp === null && lastScore == null) {
      path = this.baseUrl + `api/hive-feed?limit=${limit}`;
    }
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getHiveFeed");
      console.log(error);
      return error;
    }
  }

  async likePost(postID, isUnlike = false) {
    const path = this.baseUrl + `api/like`;
    const data = {
      postID: postID,
    };

    try {
      if (isUnlike) {
        const result = await this.deleteData(path, data);
        return result;
      } else {
        const result = await this.postData(path, data);
        return result;
      }
    } catch (error) {
      console.log("error in likePost");
      console.log(error);
      return error;
    }
  }

  async getHoldingOfAddress(holderAddress, subjectAddress) {
    const path = this.baseUrl + `api/holdings`;
    const data = {
      holder: holderAddress,
      holds: subjectAddress,
    };
    try {
      const result = await this.postData(path, data);
      return result;
    } catch (error) {
      console.log("error in getHoldingOfAddress");
      console.log(error);
      return error;
    }
  }

  async getNewUsers(limit = 20, timestamp = null) {
    const path = this.baseUrl + `api/get-new-users?limit=${limit}`;
    if (timestamp !== null) {
      path = path + `&timestamp=${timestamp}`;
    }
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getNewUsers");
      console.log(error);
      return error;
    }
  }
  async getNewPosts(limit = 50, timestamp = null) {
    let path = this.baseUrl + `api/get-new-posts?limit=${limit}`;
    if (timestamp !== null) {
      path = path + `&lastTimestamp=${timestamp}`;
    }
    try {
      const result = await this.getData(path);
      return result;
    } catch (error) {
      console.log("error in getNewPosts");
      console.log(error);
      return error;
    }
  }

  // CODE TO INITIALIZE CLIENT
  async postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async getData(url = "") {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
    });
    return response.json();
  }

  async putData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }

  async deleteData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${this.authToken}`,
      },
      body: JSON.stringify(data),
    });
    return response.json();
  }
}

export default API;
