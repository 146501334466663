import React, { useState } from "react";
import NotificationRender from "./NotificationRender";
export default function NotificationBox({
  isMobile,
  notificationData,
  loadingNotifications,
  selfData,
}) {
  // we yet have to fetch the notif from backend
  const notifList = [
    {
      actionerProfile: {
        username: "itsaditya_xyz",
        name: "Aditya",
        pfpURL:
          "https://images.bitclout.com/7561dce8a78e266e33ce4b3340f8b60ce57e2a2dcb17aa22fe2fc0eac5c14432.webp",
        address: "0x1234567890",
        sharePrice: 0.2,
      },
      actionType: "like",
      extraData: {
        postID: "0x1234567890",
        postContent: "This is a post content",
        upvotes: 10,
        postComments: 5,
      },
      timestamp: 1634029800000,
    },
    {
      actionerProfile: {
        username: "0xkuririn",
        name: "Kuririn",
        pfpURL:
          "https://diamondapp.com/api/v0/get-single-profile-picture/BC1YLhh5Xo1dCnbYeacvwpWXyXX5ptW6VDgseKne8kb7n89XP3hRf96?fallback=https://diamondapp.com/assets/img/default-profile-pic",
        address: "0x1234567890",
        sharePrice: 0.2,
      },
      actionType: "comment",
      extraData: {
        postID: "0x1234567890",
        postContent: "I like this!",
        upvotes: 12,
        postComments: 7,
        commentContent: "This is a comment",
      },
      timestamp: 1634029800000,
    },
    {
      actionerProfile: {
        username: "Clayoglesby",
        name: "Clayoglesby",
        pfpURL:
          "https://pbs.twimg.com/profile_images/1625585980135596058/KNTUUFjr_400x400.jpg",
        address: "0x1234567890",
        sharePrice: 0.2,
      },
      actionType: "buy",
      extraData: {
        shareCountTraded: 1,
        sharePrice: 0.2,
      },
      timestamp: 1634029800000,
    },

    {
      actionerProfile: {
        username: "0xKuririn",
        name: "Kuririn",
        pfpURL:
          "https://diamondapp.com/api/v0/get-single-profile-picture/BC1YLhh5Xo1dCnbYeacvwpWXyXX5ptW6VDgseKne8kb7n89XP3hRf96?fallback=https://diamondapp.com/assets/img/default-profile-pic",
        address: "0x1234567890",
        sharePrice: 0.2,
      },
      actionType: "sell",
      extraData: {
        shareCountTraded: 1,
        sharePrice: 0.2,
      },
      timestamp: 1634029800000,
    },
  ];
  return (
    <div className={`sm:border-x border-[#222222] min-h-screen w-full`}>
      <div className={`flex shadow-md `}>
        {" "}
        <div className='w-full flex   border-[#222222] border-y  bg-[#08080B]  l-0 r-0 -z-1'>
          <div
            className={`text-gray-100 font-bold px-6 py-4  flex justify-center items-center space-x-3  text-xl`}>
            Notifications
          </div>
        </div>
      </div>
      {/* <div className="w-full bg-[#1B1B24] text-gray-100 px-6 py-2">Notifications are broken. We are working to bring it back.</div> */}
      {loadingNotifications && (
        <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
          <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
        </div>
      )}
      <div className='w-full flex flex-col  '>
        {notificationData.map((notif, index) => {
          return <NotificationRender notification={notif} selfData={selfData} />;
        })}
      </div>
    </div>
  );
}
