import React from "react";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import TradeModal from "../../../components/Modals/TradeModal";
import { useState } from "react";
import { Link } from "react-router-dom";
import { getSellingPriceAfterFee } from "../../../utils/function";
export default function KeyComponent({ user, isMobile, ethPrice }) {
  const [showModal, setShowModal] = useState(false);
  const [isBuy, setIsBuy] = useState(true);

  const newComponent = {
    pfpUrl: user.pfp,
    Address: user.address,
    xUsername: user.username,
  };

  const sellingPrice = getSellingPriceAfterFee(user.supply, user.shares);
  function handleActionsClick(action) {
    if (action === "buy") {
      setIsBuy(true);
    } else {
      setIsBuy(false);
    }
    setShowModal(true);
  }

  return (
    <>
      <TradeModal
        showModal={showModal}
        setShowModal={setShowModal}
        isBuy={isBuy}
        userObject={newComponent}
      />

      <tr className=" w-full border-b border-[#222222] pb-2 ">
        <td className="py-3 px-6 ">
          <Link to={`/u/${user.username}`}>
            <div className="flex items-center space-x-2 ">
              <img src={user.pfp} alt="pfp" className="w-8 h-8 rounded-full" />
              <div className="">
                <p className="text-gray-300 text-base font-semibold sm:hover:underline">
                  {user.displayName}
                </p>
                <p className="text-gray-400 text-sm font-thin">
                  @{user.username}
                </p>
              </div>
            </div>
          </Link>
        </td>

        {!isMobile && (
          <td className="py-3 px-6 text-left">
            <p className="text-gray-400 text-sm font-thin">{user.shares}</p>
          </td>
        )}

        {!isMobile && (
          <td className="py-3 px-6 text-left">
            <p className="text-gray-400 text-sm font-thin">
              {/* ${Math.round(user.shares * user.priceEth * ethPrice * 100) / 100} */}
              ${Math.round(sellingPrice * ethPrice*100)/100}
            </p>
          </td>
        )}

        {isMobile && (
          <td className="py-3 px-6 text-left">
            <p className="text-gray-400 text-sm font-thin">
              {user.shares} x $
              {Math.round(sellingPrice * ethPrice*100)/100}
            </p>
          </td>
        )}
        <td className="py-3 px-6 text-center">
          <div className="flex items-center space-x-2   justify-end">
            <button
              className=" bg-[#132D29] border-green-500 border-2  text-white py-2 rounded-full px-2 text-sm focus:outline-none hover:bg-green-500 "
              onClick={() => {
                handleActionsClick("buy");
              }}
            >
              <FaPlus size={10} />
            </button>
            <button
              className="bg-[#241E24] border-red-500  border-2  text-white py-2 rounded-full px-2 text-sm focus:outline-none hover:bg-red-500 "
              onClick={() => {
                handleActionsClick("sell");
              }}
            >
              <FaMinus size={10} />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
}
