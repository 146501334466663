import React, { useState, useEffect } from "react";
import LeftSideBar from "../../components/LeftSidebar/LeftSidebar";

import RightSideBar from "../../components/RightSidebar/RightSidebar";
import MessageBox from "./subComponents.jsx/MessageBox";

const Messages = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className='bg-[#08080B] min-h-screen'>
      <div className='flex  sm:w-full lg:w-full xl:w-4/5  mx-auto sm:px-10 min-h-screen '>
        <div className={!isMobile && "flex-none xl:w-1/4 sm:w-1/4 lg:w-1/4 "}>
          <LeftSideBar isMobile={isMobile} currentActiveTab='messages' />
        </div>
        <div className='flex-grow sm:border-x border-[#222222]'>
            <MessageBox isMobile={isMobile} />
        </div>

        {!isMobile && (
          <div className='flex-none xl:w-1/4  sm:w-1/4 lg:w-1/3'>
            {" "}
            <RightSideBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default Messages;
