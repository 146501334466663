import { Menu, Transition } from "@headlessui/react";
import { usePrivy } from "@privy-io/react-auth";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import React, { Fragment, useContext } from "react";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import defaultPfp from "../assets/default-profile-pic.png";
import SearchModal from "./Modals/SearchModal";
import PrivyContext from "../AppStates/PrivyContext";
export default function Header() {
  const { ready, authenticated, user, login, logout } = usePrivy();
  const hasWallet = typeof user?.wallet !== "undefined";
  const hasTwitter = typeof user?.twitter !== "undefined";

  const privyContextObject = useContext(PrivyContext);

  const [showSearchModal, setShowSearchModal] = useState(false);

  const handleLogout = () => {
    logout();
    privyContextObject.updateHasLoggedIn(false);
    privyContextObject.updateUserData({});
  };
  return (
    <div className='w-full  bg-[#08080B]  l-0 r-0 -z-1'>
    
      <div className='flex w-full justify-between '>
        {hasTwitter && hasWallet && (
          <div>
            <div className='flex flex-row items-center justify-center  text-gray-200 px-3 py-3 rounded-md'>
              <Menu as='div' className='relative w-full flex text-left'>
                <Menu.Button className='flex w-full menu space-x-1 items-center justify-center focus:outline-none'>
                  <div className='flex space-x-3 items-center justify-center'>
                    <img
                      src={
                        Object.keys(privyContextObject.userData).length === 0
                          ? defaultPfp
                          : privyContextObject.userData.pfpUrl
                      }
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultPfp;
                      }}
                      alt=''
                      className='rounded-full w-8 h-8 md:w-10 md:h-10 md:ml-4'
                    />
                  </div>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter='transition ease-out duration-100'
                  enterFrom='transform opacity-0 scale-95'
                  enterTo='transform opacity-100 scale-100'
                  leave='transition ease-in duration-75'
                  leaveFrom='transform opacity-100 scale-100'
                  leaveTo='transform opacity-0 scale-95'>
                  <Menu.Items className='absolute -right-52 z-10 mt-10 w-60 divide-y divide-gray-400 dark:divide-[#2D2D33] rounded-md  shadow-lg border-gray-800 border-2 ring-1 ring-black ring-opacity-5 py-1 focus:outline-none bg-[#1B1B24] text-gray-200'>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "text-yellow-500" : ""
                          } flex justify-between menu w-full px-4 py-2 `}
                          to={`/u/${user.twitter.username}`}>
                          My Profile
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          className={`${
                            active ? "text-yellow-500" : ""
                          } flex justify-between menu w-full px-4 py-2 `}
                          to={`/settings`}>
                          Account Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => handleLogout()}
                          className={`${
                            active ? "text-yellow-500" : ""
                          } cursor-pointer flex menu justify-between w-full px-4 py-2`}>
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        )}

        <div className='flex flex-row items-center justify-end  px-3 py-3 '>
          <div className='flex items-center space-x-2'>
            <Link to='/home'>
              <img src={logo} alt='logo' className='w-7 h-7' />
            </Link>
            <Link to='/home'>
              <h1 className='text-2xl text-gray-200'>FrenHive</h1>
            </Link>
          </div>
        </div>
        {hasTwitter && hasWallet && (
          <div className='flex flex-row items-center justify-end  px-3 py-3 '>
            <button
              className='flex items-center space-x-2'
              onClick={() => setShowSearchModal(true)}>
              <FaSearch className='text-gray-300' size={20} />
            </button>
          </div>
        )}

        {!hasTwitter && (
          <div className='flex  items-center justify-center pr-2'>
            <Link
              className='border-yellow-500 bg-yellow-500 text-black border-2 rounded-lg px-3 py-1'
              to='/login'>
              Login
            </Link>
          </div>
        )}
      </div>
      <SearchModal
        showModal={showSearchModal}
        setShowModal={setShowSearchModal}
      />
    </div>
  );
}
