import React, { useState } from "react";
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { FaRegCommentAlt } from "react-icons/fa";
import { MdOutlineIosShare } from "react-icons/md";
import { timeSince } from "../../../utils/function";
import KeyPriceTag from "./KeyPriceTag";
import { Link } from "react-router-dom";
import { usePrivy } from "@privy-io/react-auth";
import PrivyContext from "../../../AppStates/PrivyContext";
import { useContext } from "react";
import defaultPfp from "../../../assets/default-profile-pic.png";
import API from "../../../API/API";
import CreateCommentModal from "../../Modals/CreateCommentModal";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
export default function PostBox({ postObject, ethPrice = 2500 }) {
  const { getAccessToken } = usePrivy();
  const privyContextObject = useContext(PrivyContext);
  const [upvoteCount, setUpvoteCount] = useState(postObject.LikesCount);
  const navigate = useNavigate();
  const [showCommentModal, setShowCommentModal] = useState(false);

  async function handleUpvote() {
    if (!privyContextObject.hasLoggedIn) {
      return alert("you need to be loggged in.");
    } else {
      setUpvoteCount(upvoteCount + 1);
      const accessToken = await getAccessToken();
      const ApiObject = new API(accessToken);
      const likeResponse = await ApiObject.likePost(postObject.PostID);
      console.log(likeResponse);
    }
  }
  function handleRoute(e) {
    const tagName = e.target.tagName;
    const parentTagName = e.target.parentNode.tagName;
    const parentId = e.target.parentNode.id;
    const currentId = e.target.id;

    if (tagName === "BUTTON" || tagName === "A") {
      return;
    }
    if (parentTagName === "BUTTON" || parentTagName == "A") {
      return;
    }

    if (parentId === "buttonBox" || currentId === "buttonBox") {
      return;
    }
    console.log(tagName);
    console.log(parentTagName);
    navigate(`/posts/${postObject.PostID}`);
  }

  function handleLinkCopy() {
    navigator.clipboard.writeText(
      `${window.location.origin}/posts/${postObject.PostID}`
    );
    toast.success("Link Copied!", {
      style: {
        backgroundColor: "#1b1b24",
        color: "white",
      },
    });
  }
  return (
    <div
      className='bg-[#08080B] py-2 border-b border-[#222222] px-3 hover:cursor-pointer hover:bg-[#101016] '
      onClick={(e) => handleRoute(e)}>
      <CreateCommentModal
        showModal={showCommentModal}
        setShowModal={setShowCommentModal}
        parentPost={postObject}
      />
      <div className='sm:flex  space-x-2 hidden sm:visible'>
        <Link to={`/u/${postObject.xUsername}`} className='flex'>
          <img
            src={postObject.pfpUrl}
            className='h-11 w-11 rounded-full'
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultPfp;
            }}
          />
        </Link>
        <Link className='flex space-x-1 mt-2' to={`/u/${postObject.xUsername}`}>
          <p className='text-gray-100 text-base font-semibold hover:underline'>
            {postObject.xDisplayName}
          </p>
          <p className='text-gray-400 text-base'>{postObject.xUsername}</p>
        </Link>

        <div className='mt-2'>
          <KeyPriceTag currentUser={postObject} ethPrice={ethPrice} />
        </div>

        <p className='text-gray-400 text-xs ml-auto mt-3'>
          {timeSince(postObject.Timestamp)}
        </p>
      </div>

      <div className='flex  space-x-2 sm:hidden'>
        <Link to={`/u/${postObject.xUsername}`} className='flex'>
          <img src={postObject.pfpUrl} className='h-11 w-11 rounded-full' />
        </Link>
        <div className='flex flex-col ml-1'>
          <Link className='flex flex-col ml-1' o={`/u/${postObject.xUsername}`}>
            <p className='text-gray-100 text-base font-semibold'>
              {postObject.xDisplayName}
            </p>
            <p className='text-gray-400 text-base'>{postObject.xUsername}</p>
          </Link>
        </div>

        <div className=''>
          <KeyPriceTag currentUser={postObject} ethPrice={ethPrice} />
        </div>

        <p className='text-gray-400 text-xs ml-auto mt-1'>
          {timeSince(postObject.Timestamp)}
        </p>
      </div>

      <p
        style={{ overflowWrap: "anywhere" }}
        className='text-gray-100 ml-14 text-start text-clip mt-2 sm:mt-0 break-words'
        id='postBody'>
        {postObject.Content.split("\n").map((line, index) => (
          <span key={index}>
            {index > 0 && <br />} {/* Add line break after the first line */}
            {line.split(" ").map((word, wordIndex) => {
              // Regular expression to match URLs
              const urlPattern = /(https?:\/\/[^\s]+)/g;

              if (word.startsWith("@")) {
                return (
                  <React.Fragment key={wordIndex}>
                    <Link
                      to={`/u/${word.slice(1)}`}
                      className='text-[#EAB308]'>
                      {word}
                    </Link>{" "}
                  </React.Fragment>
                );
              } else if (urlPattern.test(word)) {
                return (
                  <React.Fragment key={wordIndex}>
                    <a
                      href={word}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-[#EAB308]'>
                      {word}
                    </a>{" "}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={wordIndex}>
                    <span>{word}</span>{" "}
                  </React.Fragment>
                );
              }
            })}
          </span>
        ))}
      </p>
      <div className='flex space-x-1 mt-4 mb-2 ml-14' id='buttonBox'>
        <button
          className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20  justify-center'
          onClick={() => {
            handleUpvote();
          }}>
          <BiUpvote size={15} className='text-gray-400' />
          <p className='text-gray-200'>{upvoteCount}</p>
        </button>

        <button
          className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl min-w-20 justify-center items-center'
          onClick={() => {
            setShowCommentModal(true);
          }}>
          <FaRegCommentAlt size={12} className='text-gray-400' />
          <p className='text-gray-200'>{postObject.CommentsCount}</p>
        </button>

        <button
          className='flex space-x-1 text-xs bg-[#1b1b24] hover:bg-[#222222] px-2 py-1 rounded-xl justify-center items-center min-w-20 '
          onClick={handleLinkCopy}>
          <MdOutlineIosShare size={14} className='text-gray-400' />
          <p className='text-gray-200'>Share</p>
        </button>
      </div>
    </div>
  );
}
