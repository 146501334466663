import React, { useEffect, useState } from "react";
import { usePrivy } from "@privy-io/react-auth";
import { useWallets } from "@privy-io/react-auth";
import { Link } from "react-router-dom";
import API from "../../API/API";
import LogoSVG from "../../assets/logo.svg";
import toast from "react-hot-toast";
import { IoWarning } from "react-icons/io5";
export default function Login() {
  const {
    ready,
    authenticated,
    user,
    login,
    logout,
    getAccessToken,
    linkTwitter,
    unlinkTwitter,
  } = usePrivy();

  const { wallets } = useWallets();
  const [currentWallet, setCurrentWallet] = useState("");
  const [isLogging, setIsLogging] = useState(false);
  const [isReferralValid, setIsReferralValid] = useState(false);
  const [referralCode, setReferralCode] = useState("");
  const [isUserInBackend, setIsUserInBackend] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (!ready) {
      console.log("privy not laoded yet...");
      return;
    }
    if (!authenticated) {
      if (isLogging) {
        return;
      }
      console.log("not authenticated yet..");
      setIsLogging(true);
      login();
    } else {
      console.log("authenticated..");
      initWallet();
    }
  }, [wallets]);

  async function initializeBackend() {
    console.log("In intialize backend!");
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const xUsername = user.twitter.username;
    const xDisplayName = user.twitter.name;
    const xUserID = user.twitter.subject;
    const address = user.wallet.address; // NOTE: User can have either embedded wallet or privy wallet.

    const privyDID = user.id;
    const pfpUrl = ""; // privy doesn't store this shit yet lol.
    const refCode = localStorage.getItem("refCode").toString();
    console.log("refCode", refCode)
    const response = await ApiObject.addUserToBackend(
      xUsername,
      xDisplayName,
      xUserID,
      address,
      privyDID,
      pfpUrl,
      refCode
    );
    //check if response is 400 
    console.log(response);
    if(response.status === 400){
   
      localStorage.removeItem("refCode");
      window.alert("Referal code is invalid. Please enter new referral code")
      window.location.reload();
    }
    else{
      window.location.href = "/home";
    }


    // remove refCode from localStorage
    localStorage.removeItem("refCode");
  }

  async function initWallet() {
    console.log(user);
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);
    const existsResponse = await ApiObject.checkUserExistence(user.id);
    const doesExist = existsResponse.exists;
    console.log(existsResponse);
    setIsUserInBackend(doesExist);

    if (doesExist) {
      // update user in backend and route to /home
      console.log("User exists. This shold route to /home");
      const xUsername = user.twitter.username;
      const xDisplayName = user.twitter.name;
      const xUserID = user.twitter.subject;
      const address = user.wallet.address; // NOTE: User can have either embedded wallet or privy wallet.

      const privyDID = user.id;
      const pfpUrl = ""; // privy doesn't store this shit yet. we will figure something out for it

      const updateResponse = await ApiObject.updateUserInBackend(
        xUsername,
        xDisplayName,
        xUserID,
        address,
        privyDID,
        pfpUrl
      );
      window.location.href = "/home";

      return;
    } else {
      console.log("user does not exists");
      if (typeof user.twitter !== "undefined") {
        console.log("user not in backend but twitter found");
        const referalCode = localStorage.getItem("refCode");
        console.log(referalCode);
        if (referalCode) {
          setCurrentWallet(user.wallet.address);
          await initializeBackend();
          
        }
      }
    }
    setHasLoaded(true);
  }

  async function checkReferrralCode() {
    const authToken = await getAccessToken();
    const ApiObject = new API(authToken);

    //get the text after - in referralCode
    const finalCode = referralCode.split("-")[1];

    const response = await ApiObject.validateReferralCode(finalCode);
    const valid = response.valid;
    if (valid) {
      localStorage.setItem("refCode", finalCode);

      if (typeof user.twitter !== "undefined") {
        setCurrentWallet(user.wallet.address);
        initializeBackend();
        window.location.href = "/home";
      }
    } else {
      toast.error("Invalid referral code.", {
        style: {
          backgroundColor: "#1b1b24",
          color: "white",
        },
      });
    }
    setIsReferralValid(valid);
  }

  return (
    <div className='bg-[#08080B] min-h-screen'>
      {isMobile && (
        <div className='w-full  bg-[#08080B]  l-0 r-0 -z-1 border-b border-[#222222] pb-2'>
          <div className='flex w-full justify-between '>
            <div className='flex flex-row items-center justify-end  px-3 py-3 '>
              <div className='flex items-center space-x-2'>
                <Link to='/login'>
                  <img src={LogoSVG} alt='logo' className='w-7 h-7' />
                </Link>
                <Link to='/login'>
                  <h1 className='text-2xl text-gray-200'>FrenHive</h1>
                </Link>
              </div>
            </div>
            {ready && authenticated && (
              <div className='flex  items-center justify-center mx-1'>
                <button
                  onClick={logout}
                  className='bg-red-500 hover:bg-red-600 text-gray-100 px-4 py-2 rounded-md '>
                  Log out
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className='flex justify-center items-center h-screen'>
        <div className='flex sm:w-4/5 mx-auto sm:px-10 min-h-screen borde min-h  '>
          <div className={!isMobile && "flex-none w-1/4 "}>
            {!isMobile && (
              <div>
                <div className='border-b border-[#222222] w-full '>
                  <div>
                    <Link
                      className='flex items-center fixed pl-24 py-4 ml-2 '
                      to='/home'>
                      <img
                        src={LogoSVG}
                        alt='logo'
                        className='h-8 w-8 text-gray-300'
                      />
                      <p className='text-gray-300 text-base font-bold ml-2'>
                        FrenHive
                      </p>
                    </Link>
                  </div>
                </div>
                <div className='flex flex-col pl-20 py-20 justify-start items-start fixed'></div>
              </div>
            )}
          </div>

          <div className='flex-grow min-h-screen sm:border-x sm:border-[#222222]'>
            <p className='text-gray-100 text-2xl font-bold mt-10 text-center border-b border-[#222222] pb-6'>
              Welcome to FrenHive!
            </p>

            {/* load screen until privy is loaded */}
            {!ready && (
              <div className='flex justify-center items-center mt-4 bg flex-col space-y-2'>
                <div className='animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500'></div>
              </div>
            )}

            {/* give login button if privy is ready and user is not authenticated */}
            {ready && !authenticated && (
              <div className=' p-10 rounded-md flex justify-center flex-col items-center  mx-1  '>
                <p className='text-gray-100 font-lg font-semibold '>
                  Log in to get started!
                </p>
                <button
                  onClick={login}
                  className='bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-2 rounded-md mt-4 text-lg'>
                  Login
                </button>
              </div>
            )}

            {/* after login ask for refrral code*/}
            {ready &&
              authenticated &&
              !isUserInBackend &&
              !isReferralValid &&
              hasLoaded && (
                <div className=' p-10 rounded-md flex justify-center flex-col items-center  mx-1  '>
                  <p className='text-gray-100 font-lg '>
                    Enter your referral code.
                  </p>
                  <input
                    type='text'
                    className='bg-[#08080B] text-gray-100 border-b-2 border-gray-400 focus:outline-none focus:border-yellow-500 p-2 mt-4'
                    onChange={(e) => setReferralCode(e.target.value)}></input>

                  <button
                    onClick={checkReferrralCode}
                    className='bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-2 rounded-md mt-4 text-lg'>
                    Continue
                  </button>
                </div>
              )}

            {/* if referral is valid, ask to link twitter account*/}
            {ready &&
              authenticated &&
              typeof user.twitter === "undefined" &&
              isReferralValid &&
              hasLoaded && (
                <div className='p-10 rounded-md flex justify-center flex-col items-center mt-10'>
                  <p className='text-gray-100 font-lg '>
                    Link your twitter account to start using FrenHive!
                  </p>
                  <button
                    onClick={linkTwitter}
                    className='bg-yellow-400 hover:bg-yellow-500 text-gray-800 px-4 py-2 rounded-md mt-4'>
                    Link Twitter
                  </button>
                </div>
              )}

            {/* Make below code visible only while testing*/}

            {/* {ready &&
              authenticated &&
              typeof user.twitter !== "undefined" &&
              hasLoaded && (
                <div className='bg-gray-200 p-10 rounded-md'>
                  <p className='text-gray-800'>
                    You are logged in as {user.twitter.username} with address{" "}
                    {currentWallet}
                  </p>

                  <Link to='/home'>
                    <button className='bg-green-500 text-white px-4 py-2 rounded-md mt-4'>
                      Go to Home
                    </button>
                  </Link>

                  <button
                    className='bg-green-500 text-white px-4 py-2 rounded-md mt-4'
                    onClick={() => {
                      unlinkTwitter(user.twitter.subject);
                    }}>
                    Unlink twitter
                  </button>
                </div>
              )} */}

            <div className='flex items-center space-x- justify-center mt-4'>
              <span className='text-yellow-500'>
                <IoWarning size={20} />
              </span>{" "}
              <p className='text-gray-300 text-sm  text-center'>
                If something unexpected happens, please reload and try again.
              </p>
            </div>
          </div>

          {!isMobile && (
            <div className='flex-none w-1/4'>
              {ready && authenticated && (
                <div className='flex justify-end'>
                  <button
                    onClick={logout}
                    className='bg-red-500 hover:bg-red-600 text-gray-100 px-4 py-2 rounded-md mt-8'>
                    Log out
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
