import React, { useState } from "react";
import { FiFeather } from "react-icons/fi";
import CreaetPostModal from "../../Modals/CreatePostModal";
export default function CreatePostMobile() {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <CreaetPostModal showModal={showModal} setShowModal={setShowModal} />

      <button className='bg-yellow-500 px-4 py-4 rounded-full shadow-lg'
      onClick={() => setShowModal(true)}
      >
        <FiFeather className='text-2xl text-black' />
      </button>
    </div>
  );
}
