import React, { useState, useEffect, useContext } from "react";
import LeftSideBar from "../../components/LeftSidebar/LeftSidebar";
import API from "../../API/API";
import RightSideBar from "../../components/RightSidebar/RightSidebar";
import NotificationBox from "./subComponents/NotificationBox";
import PrivyContext from "../../AppStates/PrivyContext";
import { usePrivy } from "@privy-io/react-auth";
import defaultPfp from "../../assets/default-profile-pic.png";
const Notifications = () => {
  const { getAccessToken } = usePrivy();
  const privyContextObject = useContext(PrivyContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loadingNotifications, setLoadingNotifications] = useState(
    privyContextObject.notifications.length === 0
  );
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function getNotifications(selfName) {
    const accessToken = await getAccessToken();
    const ApiObject = new API(accessToken);
    const result = await ApiObject.getTrades("subject", selfName);
    let notificationArray = [];
    const trades = result.trades;
    // loop through result and get the trades that are made on the user.
    for (let i = 0; i < trades.length; i++) {
      const traderAddress = trades[i].trader;
      let traderUsername = "Anonymous";
      let pfpURL = defaultPfp;
      try {
        traderUsername = result.users[traderAddress].username;
        pfpURL = result.users[traderAddress].pfp;
      } catch (e) {
        console.log(e);
      }
      const notif = {
        actionerProfile: {
          username: traderUsername,

          pfpURL: pfpURL,
          address: traderAddress,
          sharePrice: trades[i].shareAmount,
        },
        actionType: trades[i].isBuy ? "buy" : "sell",
        extraData: {
          shareCountTraded: trades[i].shareAmount,
          sharePrice: 0,
        },
        timestamp: trades[i].blockTimestamp,
      };
      notificationArray.push(notif);
    }
    privyContextObject.updateNotifications(notificationArray);
    setLoadingNotifications(false);
    console.log(result);
    //now we only fetching trades made on the user.
  }
  useEffect(() => {
    if (privyContextObject.hasLoggedIn) {
      getNotifications(privyContextObject.userData.xUsername);
    }
  }, [privyContextObject.hasLoggedIn]);

  return (
    <div className='bg-[#08080B] min-h-screen'>
      <div className='flex  sm:w-full lg:w-full xl:w-4/5  mx-auto sm:px-10 min-h-screen  '>
        <div className={!isMobile && "flex-none xl:w-1/4 sm:w-1/4 lg:w-1/4"}>
          <LeftSideBar isMobile={isMobile} currentActiveTab='notifications' />
        </div>
        <div className='flex-grow'>
          <NotificationBox isMobile={isMobile} notificationData = {privyContextObject.notifications} loadingNotifications={loadingNotifications} selfData={privyContextObject.userData}/>
        </div>

        {!isMobile && (
          <div className='flex-none xl:w-1/4  sm:w-1/4 lg:w-1/3  '>
            {" "}
            <RightSideBar />
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
